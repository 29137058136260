import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SCompanyService {

  constructor(
    private restful: RestfulService,
  ) { }

  // 공통코드 리스트 가져오기
  getCompanyInfo(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'company',
        version: '1.0',
        action: 'getCompanyInfo'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

}
