import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SCategoryService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // tree view 용 카테고리 리스트 가져오기
  getCategoryList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'category',
        version: '1.0',
        action: 'getCategoryList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
