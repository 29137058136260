import { Component, OnInit } from '@angular/core';

import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';

@Component({
  selector: 'app-mheader',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class MheaderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public cartBadgeCnt: number = 0;      // 카트 배지 수량

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private sOrderService: SOrderService,
    private sCartService: SCartService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.sCartService.cartBadge.subscribe((data) => {
      this.cartBadgeCnt = data;
    });
  }

  /*******************************************************************************
    설  명 : 모바일 GNB 창 슬라이드
  *******************************************************************************/  
 slideMGnb() {
    this.sCartService.setMGnbOpen( true );
    this.sCartService.setMask( true );
  }

  /*******************************************************************************
    설  명 : 빠른주문창 슬라이드
  *******************************************************************************/
  slideQuickOrder() {
    this.sCartService.quickOpen.next( true );
    this.sCartService.setMask( true );
  }

}
