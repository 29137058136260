import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class SCommonService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

  private handleErrorPromise (error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 공통코드 리스트 가져오기
  getCommonListCode( code: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonListCode'
      }, {
        code: code
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 회사 정보 가져오기
  getCompanyInfo(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'company',
        version: '1.0',
        action: 'getCompanyInfo'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
