import { Component, OnInit, Input, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalOptions,
  NgbInputDatepicker, NgbDateParserFormatter, NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';

import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SCommonService } from '@shop/service/common.service';
import { SBoardService } from '@shop/service/board.service';
import { SProjectService } from '@shop/service/project.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileSelectDirective, FileItem, FileLikeObject } from 'ng2-file-upload';

const URL = config.apiFileUploadUrl;

const I18N_VALUES = {
  ko: {
    weekdays: ['월', '화', '수', '목', '금', '토', '일'],
    months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
  }
};

@Injectable()
export class I18n {
  language = 'ko';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss'],
  providers: [
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    NgbDatepickerConfig
  ]
} )
export class SEstimateComponent implements OnInit {
  @Input() boardId: any;
  @Input() rowData: any;
  @Input() quickData: any;

  public baseURL = config.baseUrl;

  public boardInfo: any = {};
  public boardData: any = {};
  public memberInfo: any = {};

  errorResponse: ApiResponse;
  public boardWriteForm: FormGroup;
  public formErrors: any = {
    board_id: '',
    subject: '',
    project_seq: '',
    contents: ''
  };

  public projectList: any = [];
  public projectTypeList: any = [];
  public groupTab: any = [];
  public groupList: any = [];

  public projectSelect2Data: any = [];

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  options: any = {
    placeholder: '카테고리 선택'
  }

  public estimateFormFile: any = {
    seq: ''
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private sBoardService: SBoardService,
    private sProjectService: SProjectService,
    private authService: AuthService,
    private globals: Globals,
    public activeModal: NgbActiveModal,
    private sCommonService: SCommonService,
    private calendar: NgbCalendar,
    private ngbDatepickerConfig: NgbDatepickerConfig,
  ) {
    ngbDatepickerConfig.displayMonths = 1;
    ngbDatepickerConfig.firstDayOfWeek = 7; // 일요일 먼저

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    let todayModel: NgbDateStruct = this.utilService.getDate('');

    this.boardWriteForm = this.formBuilder.group({
      board_seq: ["", []],
      board_id: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      project_seq: ["", []],
      project_type: ["", [Validators.required]],
      project_name: ["", [Validators.required]],
      place: ["", [Validators.required]],
      notice_sdate: [todayModel, [Validators.required]],
      notice_edate: [todayModel, [Validators.required]],
      contents: ["", [Validators.required]],
      file: ["", [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
    } );

    this.boardWriteForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.boardWriteForm, this.formErrors);
    } );
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    } );

    this.boardWriteForm.patchValue({
      board_id: this.boardId
    } );

    // 빠른주문에서 견적요청
    if (this.quickData != null && this.quickData != '') {
      this.boardWriteForm.patchValue(this.quickData);
    }

    this.getBoardInfo(this.boardId);

    // 견적요청 양식 파일 가져오기
    this.getBoardFile();

    // 프로젝트 구분
    // 프로젝트 리스트 가져오기
    this.sCommonService.getCommonListCode('PJT').then(response => {
      if (response.ResultCode) {
        this.projectTypeList = response.data;

        this.sProjectService.getProjectList().then(response => {
          if (response.ResultCode) {
            this.projectList = response.data;

            this.projectSelect2Data = response.data.reduce( (result, row) => {
              if (row.type_seq == this.projectTypeList[0].seq) {
                result.push( {
                  id: row.seq,
                  text: row.name
                } );
              }

              return result;
            }, [ {
              id: 0,
              text: "전시/행사명 선택",
            } ] );
          } else {
            this.toastrService.error(response.ResultMessage, '');
          }
        } );
      } else {
        this.toastrService.error(response.ResultMessage, '오류안내');
      }
    } );

    // 글 수정
    if (this.rowData != null && this.rowData != '') {
      this.getBoardView(this.rowData.board_id, this.rowData.board_seq);
    }
  }

  /*******************************************************************************
    설  명 : 게시글 정보 가져오기
    입력값 : 게시판 아이디, 게시판 코드
    리턴값 : 없음
  *******************************************************************************/
  getBoardView(board_id, board_seq) {
    this.sBoardService.getBoardView(board_id, board_seq, 0).then(response => {
      if (response.ResultCode) {
        this.boardData = response.data;
        this.boardData.upload = response.upload;
        this.boardData.place = this.boardData.project_place;

        this.boardData.is_pw = (this.boardData.is_pw == '1') ? true : false;
        this.boardData.popup_yn = (this.boardData.popup_yn == '1') ? true : false;
        this.boardData.is_notice = (this.boardData.is_notice == '1') ? true : false;
        this.boardData.is_titlelink = (this.boardData.is_titlelink == '1') ? true : false;
        this.boardData.tag = '#' + this.boardData.tag;

        let start_date: NgbDateStruct = this.utilService.getDate(this.boardData.notice_sdate);
        let end_date: NgbDateStruct = this.utilService.getDate(this.boardData.notice_edate);

        this.boardData.notice_sdate = start_date;
        this.boardData.notice_edate = end_date;

        this.boardWriteForm.patchValue(this.boardData);

      } else {
        this.toastrService.error(response.ResultMessage, '견적문의');

        this.activeModal.close();
      }
    } );
  }

  /*******************************************************************************
    설  명 : 견적요청 양식 파일 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardFile() {
    this.sBoardService.getBoardView('contents', '17', 0).then(response => {
      if (response.ResultCode) {
        this.estimateFormFile = response.upload[0];

      } else {
        this.toastrService.error(response.ResultMessage, '견적문의 양식');
      }
    } );
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo(board_id) {
    this.sBoardService.getBoardInfoId(board_id).then(response => {
      this.boardInfo = response.data;

      // 권한 검증
      if (this.memberInfo.level < this.boardInfo.auth_write) {
        this.toastrService.error('게시글을 작성할 권한이 없습니다.', '게시판');

        this.activeModal.close();
      } else {
        if (this.boardInfo.lvt_group == '1') {
          this.groupList = this.boardInfo.group.split('|');

          this.boardInfo.group = '전체|' + this.boardInfo.group;
          this.groupTab = this.boardInfo.group.split('|');
        }

        // 팝업 게시판일 경우
        this.boardWriteForm.patchValue({
          board_id: this.boardInfo.board_id,
          group_id: '',
        } );

        // 업로드 허용 파일 설정
        let filetype: string[] = this.boardInfo.upload_file_type.split('|');

        // 업로더 생성
        this.uploader = new FileUploader({
          url: URL,
          itemAlias: 'file',
          maxFileSize: this.boardInfo.upload_file_size * (1024 * 1024), // 최대 업로드 허용 용량
          allowedFileType: filetype // 허용 업로드 파일 타입
        } );

        // 파일업로드 설정
        this.uploader.onAfterAddingFile = (file) => {
          file.withCredentials = false;
          this.uploadProgress = '0%';
        };

        // 업로드 폼생성시 처리
        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
          form.append("board_id", this.boardInfo.board_id);
          form.append("token", this.globals.token);
        };

        // 업로드시 프로그레스바 처리
        this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
          this.uploadProgress = progress + '%';
        };

        // 업로드 용량 초과시 처리
        this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
          if (filter.name == 'fileSize') {
            this.toastrService.error('파일 업로드 용량(' + this.boardInfo.upload_file_size + 'MB)을 초과하였습니다.', '파일 업로드');
          } else if (filter.name == 'fileType') {
            this.toastrService.error('허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
          }
        };

        // 파일업로드 완료시 처리
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
          response = $.parseJSON(response);

          if (response.result) {
            this.toastrService.success(response.message, '파일 업로드');

            let files = this.boardWriteForm.controls.files.value;

            files.push({
              url: config.baseUrl + response.url,
              origin: response.origin_filename,
              size: response.size,
              thumbnail: response.thumbnail,
              thumbnail_result: response.thumbnail_result,
            } );

            this.boardWriteForm.patchValue({files: files} );

          } else {
            this.toastrService.error(response.message, '파일 업로드');
          }
        };

        setTimeout ( () => {
          this.formErrors.contents = '';
        },300);
      }
    } );
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
  *******************************************************************************/
  handleUploadFileChanged(selected) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if (selected.target.files.length < 1) return false;

    // 전체 허용 용량 검증

    let files:File[] = selected.target.files;
    let filteredFiles:File[] = [];
    for(let f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt(filename) {
    if (typeof filename == 'undefined') return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma(num) {
    let str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile(file: any, index) {
    if (confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.boardWriteForm.controls.files.value;

      tmp.splice(index, 1);

      this.boardWriteForm.patchValue({files: tmp} );
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  deleteUploadFile(upload: any, index: any) {
    if (confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      this.sBoardService.deleteUploadFile(upload.board_id, upload.board_seq, upload.seq).then(response => {
        if (response.ResultCode) {
          this.boardData.upload.splice(index, 1);

          this.toastrService.success(response.ResultMessage, '게시글 첨부파일 삭제');
        } else {
          this.toastrService.error(response.ResultMessage, '게시글 첨부파일 삭제');
        }
      } );
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage(ext: string) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if (extArray.indexOf(ext.toLowerCase() ) == -1) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 견적요청하기 처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.boardWriteForm, this.formErrors);

    if (this.boardWriteForm.valid) {
      this.sBoardService.setWrite(this.boardWriteForm)
      .then(response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage, '');
        } else {
          this.toastrService.error(response.ResultMessage, '');
        }

        this.activeModal.close();
      } )
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.boardWriteForm, this.formErrors);
      } );
    }
  }

  /*******************************************************************************
    설  명 : 프로젝트 select2 선택 시 처리
    입력값 : value
    리턴값 : 없음
  *******************************************************************************/
  projectSelect2Change(selected: any) {
    let project: any = this.projectList.find(project => (selected.id > 0) && (project.seq === selected.id) );

    if (typeof project !== 'undefined') {
      let start_date: NgbDateStruct;
      let end_date: NgbDateStruct;

      start_date = {
        year: parseInt(project.start_date.substr(0, 4) ),
        month: parseInt(project.start_date.substr(5, 2) ),
        day: parseInt(project.start_date.substr(8, 2) )
      };

      end_date = {
        year: parseInt(project.end_date.substr(0, 4) ),
        month: parseInt(project.end_date.substr(5, 2) ),
        day: parseInt(project.end_date.substr(8, 2) )
      };

      this.boardWriteForm.patchValue({
        project_seq: project.seq,
        project_name: project.name,
        project_type: project.type_seq,
        place: project.place,
        address: project.address,
        notice_sdate: start_date,
        notice_edate: end_date
      } );

      this.boardWriteForm.get('project_seq').setValidators([Validators.required] );
      this.boardWriteForm.get('project_seq').updateValueAndValidity();
    } else {
      // 전시/행사명 입력폼 필수값으로
      this.boardWriteForm.patchValue({
        project_seq: 0,
        project_name: "",
        project_type: "",
        place: "",
        notice_sdate: "",
        notice_edate: ""
      } );

      this.boardWriteForm.get('project_seq').clearValidators();
      this.boardWriteForm.get('project_seq').updateValueAndValidity();
      this.boardWriteForm.get('project_name').setValidators([Validators.required] );
      this.boardWriteForm.get('project_name').updateValueAndValidity();
      this.boardWriteForm.get('place').setValidators([Validators.required] );
      this.boardWriteForm.get('place').updateValueAndValidity();
      this.boardWriteForm.get('notice_sdate').setValidators([Validators.required] );
      this.boardWriteForm.get('notice_sdate').updateValueAndValidity();
      this.boardWriteForm.get('notice_edate').setValidators([Validators.required] );
      this.boardWriteForm.get('notice_edate').updateValueAndValidity();
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday(obj: NgbInputDatepicker, check: boolean) {
    if (check) {
      this.boardWriteForm.patchValue({
        notice_sdate: this.calendar.getToday()
      } );
      obj.close();
    } else {
      this.boardWriteForm.patchValue({
        notice_edate: this.calendar.getToday()
      } );
      obj.close();
    }
  }


}
