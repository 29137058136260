import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@app/service/auth.service';

import { SOrderService } from '@shop/service/order.service';
import { SPaymentService } from '@shop/service/payment.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

declare let INIStdPay: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class SPaymentComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역 선언
  *******************************************************************************/
  public serverName: any = '';
  public gopaymethod: any = 'Card';
  public onlycardcode: any = '';

  public project: any = {};
  public memberInfo: any = {};
  public deviceInfo: any = {};

  public payList: any = [];

  public params: any = {
    orderSeq: ''
  };

  public IniPayStd: any = {
    mid: '',
    oid: '',
    timestamp: '',
    price: '',
    mKey: '',
    sign: ''
  };

  public input: any = {
    buyername: '',
    buyertel: '',
    buyeremail: ''
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private toastrService: ToastrService,
    private sOrderService: SOrderService,
    private sPaymentService: SPaymentService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService
  ) {
    this.getDeviceInfo();
  }

  /*******************************************************************************
    설  명 : 디바이스 및 브라우저 정보 가져오기
  *******************************************************************************/
  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit() {
    const path = this.location.path();
    const url = window.location.href;
    this.serverName = url.replace(path, '');

    // 로그인 정보 가져오기
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;

      this.input.buyername = this.memberInfo.name;
      this.input.buyertel = this.memberInfo.hp;
      this.input.buyeremail = this.memberInfo.email;
    });

    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.orderSeq = ( typeof params.orderSeq == 'undefined' || params.orderSeq == '' ) ? '' : params.orderSeq;

      this.getOrderPayList();
    });
  }

  /*******************************************************************************
    설  명 : 주문 결제내역 가져오기
  *******************************************************************************/
  getOrderPayList() {
    this.sOrderService.getOrderPayList( this.params.orderSeq ).then( response => {
      if( response.ResultCode ) {
        this.project = response.data;
        this.payList = response.pay;
      } else {
        this.toastrService.error( response.ResultMessage, '주문결제');
      }
    }, error => {
      this.toastrService.error( error, '주문결제');
    });
  }

  /*******************************************************************************
    설  명 : 이니시스 결제 시작
  *******************************************************************************/
  pay() {
    let params: any = {
      price: this.project.total_amt
    };

    if( this.gopaymethod == 'Card' && this.onlycardcode == '' ) {
      this.toastrService.error( '카드 종류를 선택하시기 바랍니다.', '주문결제');
      return false;
    } else if( this.input.buyername == '' ) {
      this.toastrService.error( '주문자명을 입력하시기 바랍니다.', '주문결제');
      return false;
    } else if( this.input.buyertel == '' ) {
      this.toastrService.error( '주문자 연락처를 입력하시기 바랍니다.', '주문결제');
      return false;
    }

    this.sPaymentService.getStdPayInit( params ).then( response => {
      if( response.ResultCode ) {
        this.IniPayStd = response.data;

        INIStdPay.pay('SendPayForm_id');
      }
    });
  }

}
