import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { AuthService } from '@app/service/auth.service';

import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-terms-use',
  templateUrl: './terms-use.component.html',
  styleUrls: ['./terms-use.component.scss']
})
export class STermsUseComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public params: any = {
    type: 'list',
    board_id: 'contents',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };

  public boardData: any = {};

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
		private router: Router,
    private activatedRoute: ActivatedRoute,
		private sBoardService: SBoardService,
		private authService: AuthService,
		private domSanitizer: DomSanitizer
	) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
  *******************************************************************************/
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'contents' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.board_index = ( typeof params.board_index == 'undefined' || params.board_index == '' ) ? '1000000030000000000' : params.board_index;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      if( this.params.board_index == '' ) {
        this.toastrService.error( '게시물이 존재하지 않습니다.', '게시판' );

        this.router.navigate(
        ['/main'],
        {
          relativeTo: this.activatedRoute,
          queryParams: this.params,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

        return false;
      }

      this.getBoardInfo( this.params.board_id );
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 게시판 아이디
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo( board_id ) {
    this.sBoardService.getBoardInfoId( board_id ).then( response => {
      this.boardInfo = response.data;

      this.getBoardViewIndex( board_id, this.params.board_index );
    });
  }

  /*******************************************************************************
    설  명 : 게시글 정보 가져오기
    입력값 : 게시판 아이디, 게시판 코드
    리턴값 : 없음
  *******************************************************************************/
  getBoardViewIndex( board_id, board_index ) {

    this.sBoardService.getBoardViewIndex( board_id, board_index ).then( response => {
      if( response.ResultCode ) {
        this.boardData = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '개인정보처리방침' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getSafeHtml( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
