import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

import { config } from '@app/service/config.service';
import { DiscountService } from '@app/service/discount.service';
import { AuthService } from '@app/service/auth.service';

import { SCategoryService } from '@shop/service/category.service';
import { SProductService } from '@shop/service/product.service';
import { SProjectService } from '@shop/service/project.service';
import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';
import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-mmain',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MmainComponent implements OnInit {

  public categoryList: any = [];
  public productList: any = [];
  public popupList: any = [];

  public noticeData: any = {};
  public faqData: any = {};

  public titleDepth1: string = '전체';
  public titleDepth2: string = '';

  public baseURL: string = config.baseUrl;

  public projectSeq: any = '';

  public params: any = {
    productSeq: '',
    categoryCode: '',
  };

  isLogIn$: Observable<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private cookieService: CookieService,
    private sCategoryService: SCategoryService,
    private sProductService: SProductService,
    private sProjectService: SProjectService,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
    private sBoardService: SBoardService,
    private discountService: DiscountService,
    public authService: AuthService,
  ) { }

  ngOnInit() {
    var rollingId;

    auto();

    $(".banner").mouseover(function() {
      clearInterval(rollingId);
    });

    $(".banner").mouseout(function() {
        auto();
    });

    function auto() {
      rollingId = setInterval(function() { rollingStart(); }, 2000);
      $(".rolling-id").text(rollingId);
    }

    function rollingStart() { 
      if (($(".banner-ul").find("li").length * 204) > ($(".banner-ul").width())) {
        $(".banner-ul").animate({left: -204 + "px"}, 1500, function() {
          $(".banner-ul").find("li:first").insertAfter($(".banner-ul").find("li:last"));
          $(".banner-ul").css("left", 0);
        });
      }
    }

    // 게시판 정보 가져오기
    this.getBoardMainList();

    // 팝업 정보 가져오기
    this.getBoardPopupList();

    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.categoryCode = ( typeof params.categoryCode == 'undefined' || params.categoryCode == '' ) ? '' : params.categoryCode;

      // 카테고리 리스트 가져오기
      this.getCategoryList();

      this.isLogIn$ = this.authService.getIsLogin;

      this.isLogIn$.subscribe( login => {
        // 로그아웃 시 세션 연결 끊어짐이 되지 않아 타이머 검, 상품 리스트 가져오기
        setTimeout (() => {
          this.getProductList();
        }, 100);
      });
    });

    // 프로젝트 번호 가져오기
    this.sProjectService.getProjectSeq.subscribe(data => {
      this.projectSeq = data;
    });
  }

  ngOnDestroy() {
    clearInterval(parseInt($(".rolling-id").text()));
  }

  rollingLeft() {
    if (($(".banner-ul").find("li").length * 204) > ($(".banner-ul").width())) {
      $(".banner-ul").animate({left: -204 + "px"}, 1500, function() {
        $(".banner-ul").find("li:first").insertAfter($(".banner-ul").find("li:last"));
        $(".banner-ul").css("left", 0);
      });
    }
  }

  rollingRight() {
    if (($(".banner-ul").find("li").length * 204) > ($(".banner-ul").width())) {
      $(".banner-ul").css("left", -204);
      $(".banner-ul").find("li:last").insertBefore($(".banner-ul").find("li:first"));
      $(".banner-ul").animate({left: 0 + "px"}, 1500, function() {
      });
    }
  }

  // 전체 카테고리 표시
  mcategory_slide() {
    if ($(".category-down").hasClass("slide")) {
      $(".category-down").removeClass("slide");
      $(".category-btn").removeClass("slide");
      $("#main-mask").removeClass("slide");
    } else {
      $(".category-down").addClass("slide");
      $(".category-btn").addClass("slide");
      $("#main-mask").addClass("slide");
    }
  }

  /*******************************************************************************
    설  명 : 최근 등록된 공지사항, 자주묻는질문 가져오기
  *******************************************************************************/
 getBoardMainList() {
    this.sBoardService.getBoardMainList().then( response => {
      if( response.ResultCode ) {
        this.noticeData = response.notice;
        this.faqData = response.faq;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
  *******************************************************************************/
  getCategoryList() {
    this.sCategoryService.getCategoryList().then( response => {
      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      /* this.productList 가 없을 경우? */
      if( this.params.categoryCode.length > 0 ) {
        this.titleDepth2 = '';

        for( let item of this.categoryList ) {
          if( item.category_code == this.params.categoryCode.substr( 0, 3 ) ) {
            this.titleDepth1 = item.category_name;

            if( this.params.categoryCode.length > '3' ) {
              for( let subitem of item.children ) {
                if( subitem.category_code == this.params.categoryCode ) {
                  this.titleDepth2 = subitem.category_name;
                }
              }
            }
          }
        }

      } else {
        this.titleDepth1 = '전체';
        this.titleDepth2 = '';
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
  *******************************************************************************/
  loadList( row: any ) {
    this.params.categoryCode = row.category_code;

    //this.getProductList();
    this.router.navigate(
    ['/mobile/main'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
  *******************************************************************************/
  loadList2( row: any ) {
    this.params.categoryCode = row.category_code;

    this.mcategory_slide();

    //this.getProductList();
    this.router.navigate(
    ['/mobile/main'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상품 리스트 가져오기
  *******************************************************************************/
  getProductList() {
    this.sProductService.getProductList( this.params ).then( response => {
      if( response.ResultCode ) {
        let tmp: any = [];
        for( let item of response.data ) {
          tmp.push({
            seq: item.seq,
            sale_amt: item.sale_amt
          });

          item.discount = {
            discount_rate: 0,
            total_amt: 0
          };
        }

        this.productList = response.data;

        this.discountService.getDiscountList( this.projectSeq, tmp ).then( result => {
          // 상품에 할인 정보 저장
          for( let item of result.product ) {
            for( let item2 of this.productList ) {
              if( item.seq == item2.seq ) {
                item2['discount'] = item;
              }
            }
          }

          // 카테고리 없을 경우 데이터 처리
          if( this.params.categoryCode.length < 1 ) {
            let totalData: any = [];

            for( let item of this.categoryList ) {
              let data: any = [];

              for( let item2 of this.productList ) {
                if( item2.category_code.substr(0, 3) == item.category_code ) {
                  data.push( item2 );
                }
              }

              // 상품이 있을 경우에만
              if( data.length > 0 ) {
                totalData.push({
                  category_code: item.category_code,
                  category_name: item.category_name,
                  data: data
                });
              }
            }

            this.productList = totalData;
          }

        }, error => {
          console.log( error );
        });
      } else {
        this.productList = [];
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 팝업 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardPopupList() {
    this.sBoardService.getBoardListLimit( {board_id: 'popup', limit: '0'} ).then( response => {
      //console.log(response);
      if( response.ResultCode ) {

        if( response.data.length > 0 ) {
          response.data.forEach( row => {
            if( this.getPopupToday(row.board_seq) ) {
              if( row.layer_popup == true ) {
                row.is_popup_preview = true;

                this.popupList.push(row);
              } else {
                let url = this.baseURL + '/api/popup/popup.php?board_id=' + row.board_id + '&board_seq=' + row.board_seq;

                window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width='+row.popup_width+', height='+parseInt(row.popup_height+108)+', left='+row.screen_x+', top=' + row.screen_y );
              }
            }
          });
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPopupToday( boardSeq ) {
    if( this.cookieService.get( 'popup' + boardSeq ) == 'hidden' ) {
      return false;
    } else {
      return true;
    }
  }

  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPopupToday( row ) {
    this.cookieService.set( 'popup' + row.board_seq, 'hidden', 1 );

    row.is_popup_preview = false;
  }

  /*******************************************************************************
    설  명 : 할인율 존재 여부 체크
  *******************************************************************************/
  checkDiscountRate( discount ) {
    if( typeof discount != 'undefined' ) {
      if( typeof discount.discount_rate != 'undefined' && discount.discount_rate > 0 ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 할인금액 존재 여부 체크
  *******************************************************************************/
  checkDiscountTotalAmt( discount ) {
    if( typeof discount != 'undefined' ) {
      if( typeof discount.total_amt != 'undefined' && discount.total_amt > 0 ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 상품 상세보기
  *******************************************************************************/
  view( row: any ) {
    this.params.productSeq = row.seq;

    this.router.navigate(
    ['/mobile/goods/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goBoard( board_id ) {
    this.router.navigate(
    ['/mobile/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {board_id: board_id, type: ''},
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 빠른주문
  *******************************************************************************/
  setQuickOrder( row: any ) {

    if( row.stock_yn == false ) {
      this.toastrService.error( '품절인 상품은 주문을 할 수 없습니다. 빠른 시일내에 재고를 준비하겠습니다.', '빠른주문' );
    } else {
      row.count = 1;

      this.sCartService.setMask( true );
      //this.sOrderService.setQuickOpen( true );
      //this.sOrderService.setQuickOrder( row );
      this.sCartService.quickOpen.next( true );
      this.sCartService.setAddCart( row );
    }
  }

}
