import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '@app/service/auth.service';

import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { SQuestionAddComponent } from './add/add.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class SQuestionComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public boardInfo: any = {};
  public memberInfo: any = {};

  public params: any = {
    type: 'list',
    board_id: 'qna',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };

  public linecnt: any = [];
  public groupTab: any = [];

  public boardList = [];

  public totalCount: number;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sBoardService: SBoardService,
    private authService: AuthService,
    private modalService: NgbModal,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit() {
    $("#mypage-menu6").addClass("active");

    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? 'qna' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '전체' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 1 : parseInt(params.no);
      this.params.group = ( typeof params.group == 'undefined' || params.group == '') ? 'subject' : params.group;
      this.params.text = ( typeof params.text == 'undefined' ) ? '' : params.text;

      this.getBoardInfo( this.params.board_id );
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async getBoardInfo( board_id ) {
    await this.sBoardService.getBoardInfoId( board_id ).then( response => {
      if( response.data == null ) {
        this.toastrService.error( "존재하지 않는 게시판입니다.", "" );

        this.router.navigate( ['/main'] );
        return false;
      }

      this.boardInfo = response.data;

      if( this.params.type == '' ) {
        switch( this.boardInfo.lvt_default ) {
          case 'A': this.params.type = 'album'; break;
          case 'G': this.params.type = 'gallery'; break;
          case 'C': this.params.type = 'calendar'; break;
          default: case 'L': this.params.type = 'list';
        }
      }

      if( this.params.type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');
      else if( this.params.type == 'album' ) this.linecnt = this.boardInfo.linecnt_album.split('/');
      else if( this.params.type == 'gallery' ) this.linecnt = this.boardInfo.linecnt_gallery.split('/');

      if( this.boardInfo.lvt_group == '1' ) {
        this.boardInfo.group = '전체|' + this.boardInfo.group;
        this.groupTab = this.boardInfo.group.split('|');
      }

      this.getBoardList();
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.sBoardService.getBoardList( this.params ).then( response => {
      this.boardList = response.data;
      this.boardInfo.total_count = response.total;

      this.totalCount = parseInt( response.total );
    });
  }

  /*******************************************************************************
    설  명 : 게시판 리스트 타입 변경
    입력값 : type = L, A, C, G
    리턴값 : 없음
  *******************************************************************************/
  viewChange( type ) {
    this.params.type = type;

    this.router.navigate(
    ['/mypage/question/view'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 글 쓰기 권한
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  authWriteCheck(): boolean {
    if( this.boardInfo.auth_write <= this.memberInfo.level ) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.params.no = page;

    this.router.navigate(
    ['/mypage/question'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadList( group_id ) {
    this.params.group_id = group_id;

    this.router.navigate(
    ['/mypage/question'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 삭제 가능 여부 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authCheck( row: any ) {
    // 관리자 권한일 경우 삭제 가능
    if( this.memberInfo.level >= 80 ) {
      return true;
    }

    // 작성자의 경우
    if( this.memberInfo.mem_no == row.writer ) return true;

    return false;
  }

  /*******************************************************************************
    설  명 : 상세보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  view( row: any ) {
    let child = $("#child_"+ row.board_seq);

    if( child.length < 1 ) {
      let node = '<tr id="child_'+ row.board_seq +'"><td></td><td colspan="6" style="padding: 30px 0px 40px 0px;line-height: 150%;" class="tl">'+ row.contents_nl2br +'</td></tr>';
      $("#tr_" + row.board_seq).after( node );
    } else {
      $("#child_" + row.board_seq).remove();
    }
  }

  /*******************************************************************************
    설  명 : 게시글 등록
  *******************************************************************************/
  write() {
    const modalRef = this.modalService.open(SQuestionAddComponent, options);

    modalRef.componentInstance.boardId = this.params.board_id;
    modalRef.componentInstance.groupTab = this.groupTab;

    modalRef.result.then((result) => {
      this.getBoardList();
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 게시글 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  modify( row: any ) {
    const modalRef = this.modalService.open(SQuestionAddComponent, options);

    modalRef.componentInstance.boardId = this.params.board_id;
    modalRef.componentInstance.groupTab = this.groupTab;
    modalRef.componentInstance.rowData = row;

    modalRef.result.then((result) => {
      let child = $("#child_"+ row.board_seq);
      if( child.length > 0 ) {
        $("#child_" + row.board_seq).remove();
      }

      this.getBoardList();
    }, (reason) => {
    });

    //event.stopPropagation();
  }

  /*******************************************************************************
    설  명 : 게시글 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete( row: any ) {
    if( confirm("게시글을 삭제하시겠습니까?") ) {
      this.sBoardService.deleteBoard( this.boardInfo.board_id, row.board_seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '' );

          this.router.navigate(
          ['/mypage/question'],
          {
            relativeTo: this.activatedRoute,
            queryParams: this.params,
            queryParamsHandling: '', // remove to replace all query params by provided
          });

        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      });
    }

    //event.stopPropagation();
  }

}
