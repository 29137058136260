import { NgModule } from '@angular/core';

import { DateFormatPipe } from './date-format.pipe';
import { SafePipe } from './safe.pipe';
import { KCurrencyPipe } from './kcurrency.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    SafePipe,
    KCurrencyPipe
  ],
  exports: [
    DateFormatPipe,
    SafePipe,
    KCurrencyPipe
  ]
})
export class PipesModule {
}
