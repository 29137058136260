import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { SOrderService } from '@shop/service/order.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { SOrderCancelComponent } from '@shop/page/member/mypage/order/order-cancel/order-cancel.component';
import { SOrderDetailEditInfoComponent } from '@shop/page/member/mypage/order/order-detail/edit-info/edit-info.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class SOrderDetailComponent implements OnInit {

  public project: any = {};

  public detailList: any = [];
  public payList: any = [];

  private params: any = {
    term: '',
    sdate: '',
    edate: '',
    status: '',
    row: 10,
    no: 1,
    orderSeq: ''
  };

  public rentalSdateDiff: any = 0;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private sOrderService: SOrderService,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit() {
    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.term = ( typeof params.term == 'undefined' || params.term == '' ) ? '' : params.term;
      this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : params.sdate;
      this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : params.edate;
      this.params.status = ( typeof params.status == 'undefined' || params.status == '') ? '' : params.status;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 0 : parseInt(params.no);
      this.params.orderSeq = ( typeof params.orderSeq == 'undefined' || params.orderSeq == '' ) ? '' : params.orderSeq;

      this.getOrderDetailList();
      this.getOrderPayList();
    });
  }

  /*******************************************************************************
    설  명 : 주문 상세내역 가져오기
  *******************************************************************************/
  getOrderDetailList() {
    this.sOrderService.getOrderDetailList( this.params.orderSeq ).then( response => {
      if( response.ResultCode ) {
        this.detailList = response.data;
      } else {
        this. detailList = [];
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 결제내역 가져오기
  *******************************************************************************/
  getOrderPayList() {
    this.sOrderService.getOrderPayList( this.params.orderSeq ).then( response => {
      if( response.ResultCode ) {
        this.project = response.data;
        this.payList = response.pay;

        if( this.project.rental_sdate ) {
          const rentalSdate = moment(this.project.rental_sdate);
          const today = moment(moment().format('YYYY-MM-DD'));
          this.rentalSdateDiff = rentalSdate.diff(today, 'days');
        }
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  list() {
    this.params.orderSeq = '';

    this.router.navigate(
    ['/mypage/order/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 개별 체크
  *******************************************************************************/
  setCheck( index: number ) {
    this.detailList[ index ].isChecked = ( this.detailList[ index ].isChecked == 0 ) ? 1 : 0;
  }

  /*******************************************************************************
    설  명 : 전체 체크
  *******************************************************************************/
  setAllCheck( event ) {
    this.detailList.forEach(obj => {
      obj.isChecked = event.target.checked;
    });
  }

  /*******************************************************************************
    설  명 : 주문정보 개별 삭제
  *******************************************************************************/
  setDeleteRowOrder( event, row ) {
    event.preventDefault();
    event.stopPropagation();

    if( !confirm('선택하신 내역을 삭제하시겠습니까?') ) return false;

    this.sOrderService.setDeleteRowOrder( row.seq ).then(response => {
      if( response.ResultCode == true ) {
        this.toastrService.success(response.ResultMessage, '주문정보 삭제');
        this.getOrderDetailList();
        this.getOrderPayList();
      } else {
        this.toastrService.error(response.ResultMessage, '주문정보 삭제');
      }
    })
    .catch(response => {
      this.toastrService.error(response, '주문정보 삭제');
    });
  }

  /*******************************************************************************
    설  명 : 주문정보 선택 삭제
  *******************************************************************************/
  setDeleteSelectedOrder() {
    if( !confirm('선택하신 내역을 삭제하시겠습니까?') ) return false;

    let cnt: number = 0;
    for( let item of this.detailList ) {
      if( item.isChecked ) {
        cnt = cnt + 1;
      }
    }

    if( cnt < 1 ) {
      this.toastrService.error( '삭제할 상품을 하나 이상 체크해 주세요.', '주문정보 삭제');
      return false;
    }

    this.sOrderService.setDeleteSelectedOrder( this.detailList ).then(response => {
      if( response.ResultCode == true ) {
        this.toastrService.success(response.ResultMessage, '주문정보 삭제');
        this.getOrderDetailList();
        this.getOrderPayList();
      } else {
        this.toastrService.error(response.ResultMessage, '주문정보 삭제');
      }
    })
    .catch(response => {
      this.toastrService.error(response, '주문정보 삭제');
    });
  }

  /*******************************************************************************
    설  명 : 주문접수일 때 수량변경
  *******************************************************************************/
  setChangeOrderDetail( row: any, event ) {
    if( !confirm('선택하신 수량으로 변경하시겠습니까?') ) return false;

    // 대여일수 : event.target.parentElement.previousElementSibling.firstElementChild.value
    // 수량 : event.target.previousElementSibling.value
    let daycount: any;
    let qty: any;

    if( event.target.parentElement.previousElementSibling.firstElementChild !== null ) {
      daycount = event.target.parentElement.previousElementSibling.firstElementChild.value;

      if( daycount == '' || daycount < 1 ) {
        this.toastrService.error('대여일수는 숫자로 1 이상 입력하시기 바랍니다.', '대여일수 변경');
        return false;
      }
    }

    if( event.target.previousElementSibling !== null ) {
      qty = event.target.previousElementSibling.value;
    }

    if( qty == '' || qty < 1 ) {
      this.toastrService.error('수량은 숫자로 1 이상 입력하시기 바랍니다.', '수량 변경');
      return false;
    }

    this.sOrderService.setChangeOrderDetail( row.seq, daycount, qty ).then(response => {
      if( response.ResultCode == true ) {
        this.toastrService.success(response.ResultMessage, '수량 변경');
        this.getOrderDetailList();
        this.getOrderPayList();
      } else {
        this.toastrService.error(response.ResultMessage, '수량 변경');
      }
    })
    .catch(response => {
      this.toastrService.error(response, '수량 변경');
    });

  }

  /*******************************************************************************
    설  명 : 정보변경
  *******************************************************************************/
  setChangeOrderInfo() {
    const modalRef = this.modalService.open(SOrderDetailEditInfoComponent, options);

    modalRef.componentInstance.orderSeq = this.params.orderSeq;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 주문취소
  *******************************************************************************/
  setOrderCancel() {
    if( !confirm('주문을 취소하시겠습니까?') ) return false;

    this.sOrderService.setOrderCancel( this.params.orderSeq ).then(response => {
      if( response.ResultCode == true ) {
        this.toastrService.success(response.ResultMessage, '주문 취소');
        this.router.navigate(['/mypage/order/list']);
      } else {
        this.toastrService.error(response.ResultMessage, '주문 취소');
      }
    })
    .catch(response => {
      this.toastrService.error(response, '주문 취소');
    });
  }

  /*******************************************************************************
    설  명 : 주문 및 결제취소
  *******************************************************************************/
  setOrderPaymentCancel() {
    if( !confirm('주문 및 결제를 취소하시겠습니까?') ) return false;

    this.sOrderService.setOrderPaymentCancel( this.params.orderSeq ).then(response => {
      if( response.ResultCode == true ) {
        this.toastrService.success(response.ResultMessage, '결제 취소');
        this.router.navigate(['/mypage/order/list']);
      } else {
        this.toastrService.error(response.ResultMessage, '결제 취소');
      }
    })
    .catch(response => {
      this.toastrService.error(response, '결제 취소');
    });
  }

  /*******************************************************************************
    설  명 : 취소/환불 신청
  *******************************************************************************/
  setOrderRefund( requestGbn ) {
    const modalRef = this.modalService.open(SOrderCancelComponent, options);

    modalRef.componentInstance.orderSeq = this.params.orderSeq;
    modalRef.componentInstance.requestGbn = requestGbn;

    modalRef.result.then((result) => {
      if( result ) {
        this.router.navigate(['/mypage/cancel']);
      } else {
        this.router.navigate(['/member/login']);
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 거래명세서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openStatement() {
    let url = '/member/mypage/print/statement?seq=' + this.params.orderSeq;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=790, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 견적서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openEstimate() {
    let url = '/member/mypage/print/estimate?seq=' + this.params.orderSeq;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=790, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 결제하기
  *******************************************************************************/
  setOrderPayment() {
    this.router.navigate(
    ['/mypage/order/payment'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

}
