import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-result',
  templateUrl: './order-result.component.html',
  styleUrls: ['./order-result.component.scss']
})
export class SOrderResultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
