import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Routes, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';
import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class SHeaderComponent implements OnInit {
  @Input() productData: any;

  public memberInfo: any = {};

  isLogin$: Observable<boolean>;

  public location: any = {
    depth1_category_code: '',
    depth1_category_name: '',
    depth2_category_code: '',
    depth2_category_name: '',
    name: ''
  };

  public path: any = [];

  public params: any = {
    productSeq: '',
    categoryCode: ''
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
		private toastrService: ToastrService,
    private memberService: SMemberService,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
    private authService: AuthService,
  ) {
    this.isLogin$ = this.authService.getIsLogin;
  }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

    // 상품 카테고리일 경우
    if( this.productData != undefined ) {
      this.location = this.productData;

      this.path = [
        {
          title: this.location.depth1_category_name,
          code: this.location.depth1_category_code
        },
        {
          title: this.location.depth2_category_name,
          code: this.location.depth2_category_code
        },
        {
          title: this.location.name,
          code: ''
        },
      ];

    // 일반 페이지일 경우
    } else {
      // 최상위 부모가 있는지 검사해서 단계 표시
      this.activatedRoute.queryParams.subscribe( data => {

        this.path = [];
        let obj: any = this.activatedRoute.parent.parent;
        if( typeof obj != 'undefined' && obj != null && typeof obj.data != 'undefined' && obj.data != null ) {
          obj.data.subscribe( async data => {
            if( typeof data[0] != 'undefined' ) {
              if( data[0].title != '' ) this.path.push( data[0] );
            }
          });
        }

        // 상위 부모가 있는지 검사해서 단계 표시
        obj = this.activatedRoute.parent;
        if( typeof obj != 'undefined' && obj != null && typeof obj.data != 'undefined' && obj.data != null ) {
          obj.data.subscribe( async data => {
            if( typeof data[0] != 'undefined' && typeof data[0].board_id != 'undefined' ) {
              this.activatedRoute.queryParams.subscribe( async params => {
                // 게시판 처리
                let arr: any = [];
                for( let key in data ) arr.push( data[key] );

                for( let item of arr ) {
                  if( typeof item != 'undefined' && typeof item.board_id != 'undefined' )
                    if( item.board_id == params.board_id ) {
                      let check = false;
                      for( let item1 of this.path ) {
                        if( item1.title == item.title ) check = true;
                      }

                      if( ! check ) this.path.push( item );
                    }
                }
              });

            } else if( typeof data[0] != 'undefined' ) {
              if( data[0].title != '' ) this.path.push( data[0] );
            }
          });
        }

        // 현재 단계 표시
        this.activatedRoute.data.subscribe( async data => {
          if( typeof data[0] != 'undefined' && data[0] != null ) {
            if( typeof data[0].board_id != 'undefined' && data[0].board_id != null ) {
              this.activatedRoute.queryParams.subscribe( async params => {
                let arr: any = [];
                for( let key in data ) arr.push( data[key] );

                for( let item of arr ) {
                  if( typeof item != 'undefined' && typeof item.board_id != 'undefined' )
                    if( item.board_id == params.board_id ) {
                      let check = false;
                      for( let item1 of this.path ) {
                        if( item1.title == item.title ) check = true;
                      }

                      if( ! check ) this.path.push( item );
                    }
                }
              });

            } else if( data[0].title != '' ) {
              this.path.push( data[0] );
            }
          }
        });
      });
    }
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  setLogout() {
    this.memberService.setLogout().then( async response => {
      if( response['ResultCode'] == true ) {
        this.toastrService.success(response['ResultMessage'], '');

        await this.authService.logout('shop');

				await this.sCartService.quickOpen.next( false );

        this.router.navigate(['/main']);
      } else {
        this.toastrService.error(response['ResultMessage'], '');
      }
    })
    .catch(response => {
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
  *******************************************************************************/
  loadList( code: any ) {
    if( !code || code == '' ) return false;

    this.params.categoryCode = code;

    this.router.navigate(
    ['/main'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

}
