import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SOrderService } from '@shop/service/order.service';
import { SCommonService } from '@shop/service/common.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-cancel',
  templateUrl: './order-cancel.component.html',
  styleUrls: ['./order-cancel.component.scss']
})
export class SOrderCancelComponent implements OnInit {
  @Input() orderSeq: any;
  @Input() requestGbn: any;

  private memberInfo: any = {};
  private isLogin: boolean;

  public project: any = {};
  public reasonList: any = [];

  errorResponse: ApiResponse;
  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private authService: AuthService,
    private sOrderService: SOrderService,
    private sCommonService: SCommonService,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      order_seq: ["", [Validators.required]],
      request_gbn: ["", [Validators.required]],
      refund_reason: ["", [Validators.required]],
      refund_memo: ["", [Validators.required]],
      total_amt: [0, [Validators.required]],
      refund_yn: ["", []],
      refund_account: ["", [Validators.required]],
      refund_bank: ["", [Validators.required]],
      refund_depositary: ["", [Validators.required]],
      list: [[], [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.authService.getIsLogin.subscribe(data => {
      this.isLogin = data;

      if( !this.isLogin ) {
        this.activeModal.close(false);
      }
    });

    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;

      if( this.memberInfo ) {
        this.form.patchValue({
          refund_bank: this.memberInfo.refund_bank,
          refund_account: this.memberInfo.refund_account,
          refund_depositary: this.memberInfo.refund_depositary
        });
      }
    });

    this.form.patchValue({
      order_seq: this.orderSeq,
      request_gbn: this.requestGbn
    });

    this.getCommonListCode();
    this.getOrderPayList();
    this.getOrderDetailList();
  }

  /*******************************************************************************
    설  명 : 프로젝트 구분 리스트 가져오기
  *******************************************************************************/
  getCommonListCode() {
    this.sCommonService.getCommonListCode( 'RSN' ).then( response => {
      if( response.ResultCode ) {
        this.reasonList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 상세내역 가져오기
  *******************************************************************************/
  getOrderDetailList() {
    this.sOrderService.getOrderDetailList( this.orderSeq ).then( response => {
      if( response.ResultCode ) {
        let list: any = [];
        response.data.forEach(item => {
          item.isChecked = true;
          item.count = item.qty;

          list.push(item);
        });

        this.form.patchValue({ list: list });

        this.setCalcAmt();
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문 결제내역 가져오기
  *******************************************************************************/
  getOrderPayList() {
    this.sOrderService.getOrderPayList( this.orderSeq ).then( response => {
      if( response.ResultCode ) {
        this.project = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 개별 체크
  *******************************************************************************/
  setCheck( index: number, event ) {
    if( event.target.localName == 'input' && event.target.type == 'number' ) return false;

    this.form.controls.list.value[ index ].isChecked = ( this.form.controls.list.value[ index ].isChecked == 0 ) ? 1 : 0;

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 전체 체크
  *******************************************************************************/
  setAllCheck( event ) {
    let list: any = this.form.controls.list.value;

    list.forEach(obj => {
      if( obj.status <= 10 ) {
        obj.isChecked = event.target.checked;
      }
    });

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeCount( row, event ) {
    if( parseInt(event.target.value) > parseInt(row.qty) )
      row.count = parseInt(row.qty);
    else
      row.count = parseInt(event.target.value);

    this.setCalcAmt();
  }

  /*******************************************************************************
    설  명 : 전체 금액 계산
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setCalcAmt() {
    let list: any = this.form.controls.list.value;
    let total_amt: number = 0;

    for( let item of list ) {
      //if( item.isChecked ) {
        let dc_amt: any = ( item.dc_amt / item.qty );
        let amt: any = ( parseInt(item.unit_amt) - dc_amt ) * item.count;
        let vat: any = ( ( item.tax_yn == '1' ) ? ( Math.round( parseInt(item.unit_amt) - dc_amt ) / 10 ) : 0 ) * item.count;

        total_amt += amt + vat;

        item.refund_amt = amt + vat;
        item.refund_discount_amt = dc_amt * item.count;
        item.refund_vat = vat;
      //}
    }

    this.form.patchValue({
      total_amt: total_amt,
      list: list
    });
  }

  /*******************************************************************************
    설  명 : 주문취소처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if( this.form.valid ) {
      this.sOrderService.setRefundSave( this.form ).then(response => {
        if( response['ResultCode'] == true ) {
          this.toastrService.success(response['ResultMessage'], '환불요청 성공');
          this.activeModal.close(true);
        } else {
          this.toastrService.error(response['ResultMessage'], '환불요청 실패');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    }
  }

}
