import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mmypage-order',
  templateUrl: './mypage-order.component.html',
  styleUrls: ['./mypage-order.component.scss']
})
export class MmypageOrderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	$("#mmypage-menu3").addClass("active");
  }

}
