import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mypage-header',
  templateUrl: './mypage-header.component.html',
  styleUrls: ['./mypage-header.component.scss']
})
export class SMypageHeaderComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수 선언
  *******************************************************************************/
  public memberInfo: any = {};

  public headerName: any;   // 회원명
  public price: any = 0;        // 총 이용 금액

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private sMemberService: SMemberService,
    private toastrService: ToastrService,
    public router: Router,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getTotalAmtUsed();

    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;

      this.headerName = ( typeof this.memberInfo.name == 'undefined' ) ? '비회원' : this.memberInfo.name;
    });
  }

  /*******************************************************************************
    설  명 : 총 이용금액 가져오기
  *******************************************************************************/
  getTotalAmtUsed() {
    this.sMemberService.getTotalAmtUsed().then(response => {
      if( response.ResultCode == true ) {
        this.price = response.data;
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }
}
