import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SOrderService {

  constructor(
    private restful: RestfulService,
    private toastrService: ToastrService,
  ) {}

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  /*******************************************************************************
    설  명 : 장바구니 리스트 가져오기
  *******************************************************************************/
  getCartList(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getCartList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 장바구니 추가(여러개)
  *******************************************************************************/
  setAddCart( data: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setAddCart'
      }, data
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 장바구니 추가(단일)
  *******************************************************************************/
  setAddCartSingle( params: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setAddCartSingle'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 장바구니 수량 변경
  *******************************************************************************/
  setEditCart( params: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setEditCart'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 장바구니 선택 삭제
  *******************************************************************************/
  setDeleteSelectCart( list: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setDeleteSelectCart'
      }, {
        params: list
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 장바구니 비우기
  *******************************************************************************/
  setDeleteCart(): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setDeleteCart'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 비회원 장바구니 상품정보 가져오기
  *******************************************************************************/
  getCartProduct(data:any): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getCartProduct'
      }, {
        data: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문하기
  *******************************************************************************/
  setOrderSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문정보 변경하기
  *******************************************************************************/
  setEditOrderInfo( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setEditOrderInfo'
      }, form.value
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문내역 리스트 가져오기
  *******************************************************************************/
  getOrderList( params ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문내역 상세정보 가져오기
  *******************************************************************************/
  getOrderDetail( seq: number ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderDetail'
      }, {
        orderSeq: seq
      }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문 상세내역 리스트 가져오기
  *******************************************************************************/
  getOrderDetailList( orderSeq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderDetailList'
      }, {
        order_seq: orderSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문 결제내역 리스트 가져오기
  *******************************************************************************/
  getOrderPayList( orderSeq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderPayList'
      }, {
        order_seq: orderSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문 상세내역 출력 리스트 가져오기
  *******************************************************************************/
  getOrderPrintList( orderSeq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getOrderPrintList'
      }, {
        order_seq: orderSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문접수일 때 대여일수 및 수량 변경
  *******************************************************************************/
  setChangeOrderDetail( seq, daycount, qty ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setChangeOrderDetail'
      }, {
        seq: seq,
        daycount: daycount,
        qty: qty
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문 취소 처리
  *******************************************************************************/
  setOrderCancel( seq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderCancel'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문내역 개별 삭제
  *******************************************************************************/
  setDeleteRowOrder( seq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setDeleteRowOrder'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문내역 선택 삭제
  *******************************************************************************/
  setDeleteSelectedOrder( list: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setDeleteSelectedOrder'
      }, {
        data: list
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 환불요청
  *******************************************************************************/
  setRefundSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setRefundSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 환불 리스트 가져오기
  *******************************************************************************/
  getRefundList( params ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getRefundList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 환불 상세정보 가져오기
  *******************************************************************************/
  getRefundDetail( refundSeq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'getRefundDetail'
      }, {
        refund_seq: refundSeq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 환불 요청 삭제
  *******************************************************************************/
  setRefundCancel( seq ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setRefundCancel'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 환불계좌정보 변경
  *******************************************************************************/
  setRefundAccountSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setRefundAccountSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  /*******************************************************************************
    설  명 : 주문 및 결제 취소
  *******************************************************************************/
  setOrderPaymentCancel( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'shop',
        service: 'order',
        version: '1.0',
        action: 'setOrderPaymentCancel'
      }, {
        orderSeq: seq
      }
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

}
