/*******************************************************************************
  설명 : 전역 변수 및 JWT 토큰 관리 클래스
  작성일 : 2019-07-07
  작성자 : 송영석
*******************************************************************************/
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { config } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class Globals {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  // token data
  public token : string = '';
  public tokenData : object  = {
    header: {
      typ: 'JWT',
      alg: 'HS256'
    },
    payload: {
      iss: config.baseUrl,
      exp: '',
      memNo: ''
    },
    signature: ''
  };

  // api base url
  public apiBaseUrl: string = config.apiBaseUrl + '/index.php';

  // indicator
  public indicator: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public indicator$ = this.indicator.asObservable();

  /*******************************************************************************
    설명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor() {

    // 로컬 세션 스토리지에 저장된 토큰을 불러온다.
    //this.token = sessionStorage.getItem( config.cookie_token );
    this.token = this.getCookie( config.cookie_token );

    if( this.token !== null )
      this.setToken( this.token );
  };

  /*******************************************************************************
    설명 : Indicator check
    입력값 : token = 서버에서 받은 토큰 문자열
    리턴값 : 없음
  *******************************************************************************/
  public getIndicator() {
    return this.indicator.subscribe( value => {
      return value;
    });
  }

  /*******************************************************************************
    설명 : 토큰 hash 저장
    입력값 : token = 서버에서 받은 토큰 문자열
    리턴값 : 없음
  *******************************************************************************/
  public setToken( token : string ) {
    if( token == null ) return;

    this.token = token;

    let jsonData = token.split( '.' );
    if( jsonData.length !== 3 ) return;

    let header = this.b64DecodeUnicode( jsonData[0] );
    let payload = this.b64DecodeUnicode( jsonData[1] );

    this.tokenData = {
      header: header,
      payload: payload,
      signature: jsonData[2]
    }

    // save session storage 브라우저 리프레쉬 로그인 끊김 차단
    //sessionStorage.setItem( config.cookie_token, token );
    this.setCookie( config.cookie_token, token, 1 );
  }

  /*******************************************************************************
    설명 : 토큰 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public clearToken() {
    this.token = '';
    this.tokenData = {
      header: {
        typ: 'JWT',
        alg: 'HS256'
      },
      payload: {
        iss: config.baseUrl,
        exp: '',
        memNo: ''
      },
      signature: ''
    }

    //sessionStorage.removeItem( config.cookie_token );
    this.deleteCookie( config.cookie_token );
  }

  /*******************************************************************************
    설명 : base64_decode
    입력값 : str = 문자열
    리턴값 : base64 decode 문자열
  *******************************************************************************/
  private b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  /*******************************************************************************
    설명 : base64_encode
    입력값 : str = 문자열
    리턴값 : base64 encode 문자열
  *******************************************************************************
  private base64EncodingUTF8(str) {
    var encoded = new TextEncoderLite('utf-8').encode(str);
    var b64Encoded = base64js.fromByteArray(encoded);
    return b64Encoded;
  }
  */

  /*******************************************************************************
    설  명 : localStorage 사용여부 확인
  *******************************************************************************/
  storageAvailable(type) {
    var storage;
    try {
      storage = window[type];
      var x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);

        return true;
    } catch(e) {
      return e instanceof DOMException && (
        // Firefox를 제외한 모든 브라우저
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // 코드가 존재하지 않을 수도 있기 떄문에 이름 필드도 확인합니다.
        // Firefox를 제외한 모든 브라우저
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // 이미 저장된 것이있는 경우에만 QuotaExceededError를 확인하십시오.
        (storage && storage.length !== 0);
    }
  }

  /*******************************************************************************
    설  명 : 로컬 or 세션 스토리지에 저장하기
    입력값 : key = 키 값, data = 저장 데이터
    리턴값 : 없음
  *******************************************************************************/
  save( key: any, data: any ) {
    // 로컬 스토리지가 사용 가능 할 경우
    if( this.storageAvailable( 'localStorage' ) ) {
      localStorage.setItem( key, JSON.stringify(data) );

    // 세션 스토리지가 사용 가능 할 경우
    } else {
      sessionStorage.setItem( key, JSON.stringify(data) );
    }
  }

  /*******************************************************************************
    설  명 : 로컬 or 세션 스토리지에서 불러오기
    입력값 : key = 키 값
    리턴값 : 저장 데이터
  *******************************************************************************/
  load( key: any ): any {
    let data: any;

    // 로컬 스토리지가 사용 가능 할 경우
    if( this.storageAvailable( 'localStorage' ) ) {
      data = localStorage.getItem( key );

    // 세션 스토리지가 사용 가능 할 경우
    } else {
      data = sessionStorage.getItem( key );
    }

    if( data == null ) return false;
    else return data;
  }

  /*******************************************************************************
    설  명 : 로컬 or 세션 스토리지에서 삭제
    입력값 : key = 키 값
    리턴값 : true / false
  *******************************************************************************/
  deleteData( key: any ): any {
    // 로컬 스토리지가 사용 가능 할 경우
    if( this.storageAvailable( 'localStorage' ) ) {
      return localStorage.removeItem( key );

    // 세션 스토리지가 사용 가능 할 경우
    } else {
      return sessionStorage.removeItem( key );
    }
  }

  /*******************************************************************************
    설명 : 쿠키 저장
    입력값 : name, value, day
    리턴값 : 없음
  *******************************************************************************/
  setCookie(name, value, day) {
    let date = new Date();
    date.setTime(date.getTime() + day * 60 * 60 * 24 * 1000);

    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
  }

  /*******************************************************************************
    설명 : 쿠키 값 불러오기
    입력값 : name
    리턴값 : value
  *******************************************************************************/
  getCookie(name) {
    let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value ? value[2] : null;
  };

  /*******************************************************************************
    설명 : 쿠키 삭제
    입력값 : name
    리턴값 : 없음
  *******************************************************************************/
  deleteCookie(name) {
    let date = new Date();
    document.cookie = name + "= " + "; expires=" + date.toUTCString() + "; path=/";
  }
}
