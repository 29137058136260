import { Component, OnInit } from '@angular/core';

declare global {
  interface Window {
    INIStdPay: any;
  }
}

@Component({
  selector: 'app-inistdpay-close',
  templateUrl: './inistdpay-close.component.html',
  styleUrls: ['./inistdpay-close.component.scss']
})
export class SInistdpayCloseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

	ngAfterViewInit() {
		parent.INIStdPay.viewOff();
	}

}
