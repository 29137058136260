import { Injectable, NgModuleFactoryLoader } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';
import { UtilService } from '@app/service/util.service';

@Injectable()
export class AuthLoginCheck implements CanActivate {

  public redirectURL: string = '';

  public deviceInfo = null;

  constructor(
    private router: Router,
    private globals: Globals,
    private member: MemberService,
    private toastrService: ToastrService,
    private readonly loader: NgModuleFactoryLoader,
    private authService: AuthService,
    private deviceService: DeviceDetectorService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.redirectURL = state.url;
		let mode = ( this.redirectURL.indexOf('/admin') > -1 ) ? 'admin' : 'shop';

    return this.member.getMemberInfo( mode ).then( response => {
      if( response['ResultCode'] == true ) {
				this.authService.login( response );
      } else {
        this.authService.logout( mode );
      }

			return true;
    })
    .catch(response => {
      return false;
    });
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(next, state);
  }
}