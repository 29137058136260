import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { filter, pairwise } from 'rxjs/operators';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';
import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-non-member-join',
  templateUrl: './non-member-join.component.html',
  styleUrls: ['./non-member-join.component.scss']
})
export class SNonMemberJoinComponent implements OnInit {

	errorResponse: ApiResponse;
  public nonMemberAddForm: FormGroup;
  public formErrors = {
		name: '',
		pw: '',
		pw_check: '',
		email1: '',
		email2: '',
		hp1: '',
		hp2: '',
	};

  public quickOrderList: any;

  public params: any = {
    type: '',
		productSeq: '',
    categoryCode: ''
  };

  constructor(
		private activatedRoute: ActivatedRoute,
		private utilService: UtilService,
		private toastrService: ToastrService,
		private router: Router,
		private formBuilder: FormBuilder,
		private modalService: NgbModal,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
		public memberService: SMemberService,
		public authService: AuthService,
	) {
		this.buildForm();
	}

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.nonMemberAddForm = this.formBuilder.group({
			name:[ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
			pw:[ '', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      pw_check:[ '', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
			email1:[ '', [Validators.required] ],
			email2:[ '' ],
			hp1:[ '010', [Validators.required] ],
			hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{7,8}$')] ],
    });

    this.nonMemberAddForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.nonMemberAddForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? '' : params.type;
			this.params.productSeq = ( typeof params.productSeq == 'undefined' || params.productSeq == '' ) ? '' : params.productSeq;
			this.params.categoryCode = ( typeof params.categoryCode == 'undefined' || params.categoryCode == '' ) ? '' : params.categoryCode;
    });

		//this.sOrderService.quickOrder.subscribe((data: any) => {
			//this.quickOrderList = data
		//});
  }

  // 핸드폰번호 selectbox
  selectbox_pulldown(event) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    if (findrowA.parent().hasClass("pulldown")) {
      findrowA.parent().removeClass("pulldown")
    } else {
			findrowA.parent().addClass("pulldown")
    }
  }

	ngDoCheck() {
		// 이메일 선택에 따른 패턴 부여
		if( this.nonMemberAddForm.get('email2').value == "" ) {
			this.nonMemberAddForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] );
			this.nonMemberAddForm.get('email1').updateValueAndValidity();
		} else {
			this.nonMemberAddForm.get('email1').setValidators( [Validators.required, Validators.pattern('^[a-z0-9._-]*$')] );
			this.nonMemberAddForm.get('email1').updateValueAndValidity();
		}
	}

	// SELECT 선택 처리
	selectChange(event, element) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    findrowA.parent().parent().parent().find(".select-title > span").text(findrowA.text());
    $(".select-box").removeClass("pulldown");

		if( event.target.text == "직접입력" ) {
			this.nonMemberAddForm.get( element ).setValue( "" );
		} else
			this.nonMemberAddForm.get( element ).setValue( event.target.text );
	}

  /*******************************************************************************
    설  명 : 장바구니 저장
  *******************************************************************************/
	setAddCart() {
		this.sOrderService.setAddCart( this.quickOrderList )
		.then(response => {
			if( response['ResultCode'] == true ) {
				this.toastrService.success(response['ResultMessage'], '');

				this.sCartService.setDeleteCart();
				this.sCartService.quickOpen.next( false );

        this.router.navigate(
        ['/mypage/cart'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {refresh:'true'},
          queryParamsHandling: '', // remove to replace all query params by provided
        });
			} else {
				this.toastrService.error(response['ResultMessage'], '');
			}
		})
		.catch(response => {
		});
	}

  /*******************************************************************************
    설  명 : 비회원가입 처리
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.nonMemberAddForm, this.formErrors);

    if( this.nonMemberAddForm.valid ) {
      this.memberService.setNonMemberSave( this.nonMemberAddForm )
      .then(response => {
        if( response['ResultCode'] == true ) {
					this.authService.login(response);

          this.toastrService.success(response['ResultMessage'], '');

          if( this.params.type == 'cart' ) {
            this.setAddCart();
          } else {
            this.router.navigate(
            ['/mypage/order/list'],
            {
              relativeTo: this.activatedRoute,
              queryParams: this.params,
              queryParamsHandling: '', // remove to replace all query params by provided
            });
          }
        } else {
          this.toastrService.error(response['ResultMessage'], '');
        }
      })
      .catch(response => {
				this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.nonMemberAddForm, this.formErrors);
      });
    }
  }

}
