import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbInputDatepicker, NgbDateStruct, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';
import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SCommonService } from '@shop/service/common.service';
import { SBoardService } from '@shop/service/board.service';
import { SCategoryService } from '@shop/service/category.service';
import { SProductService } from '@shop/service/product.service';

import * as moment from 'moment';
import * as jexcel from 'jexcel';

let table: any;
let rowIndex: number;
let rowDeleteCount: number;

@Component({
  selector: 'app-estimate-request',
  templateUrl: './estimate-request.component.html',
  styleUrls: ['./estimate-request.component.scss']
})
export class MestimateRequestComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() boardSeq: any;

  errorResponse: ApiResponse;

  public form: FormGroup;
  public formErrors = {};

  public boardInfo: any = {};

  public projectTypeList: any = [];
  public categoryList: any = [];
  public categoryNameList: any = [];
  public productNameList: any = [];

  private dateModel: NgbDateStruct;

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    const todayModel: NgbDateStruct = this.utilService.getDate('');

    this.form = this.formBuilder.group({
      board_seq: ['', []],
      board_id: ['estimate', []],
      project_group: ['', [Validators.required]],
      project_name: ['', [Validators.required]],
      project_place: ['', [Validators.required]],
      notice_sdate: [todayModel, [Validators.required]],
      notice_edate: [todayModel, [Validators.required]],
      contents: ['', [Validators.required]],
      excel: [[], [] ],
    } );

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    } );
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private globals: Globals,
    private calendar: NgbCalendar,
    private ngbDatepickerConfig: NgbDatepickerConfig,
    private sCommonService: SCommonService,
    private sBoardService: SBoardService,
    private sCategoryService: SCategoryService,
    public sProductService: SProductService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.getProjectTypeList();

    // 파라미터 가져오기
    this.activatedRoute.queryParams.subscribe(params => {
      this.boardSeq = ( typeof params.boardSeq == 'undefined' || params.boardSeq == '' ) ? '' : params.boardSeq;

      this.form.patchValue({
        board_seq: this.boardSeq
      });
    });
  }

  async ngAfterViewInit() {

    let categoryList: any = [];
    const categoryNameList: any = [];
    let productListAll: any = [];
    let productNameList: any = [];

    let quickOrderList: any = this.globals.load( 'thefirstpro_quickOrderList' );
    // console.log(quickOrderList);
    this.globals.deleteData( 'thefirstpro_quickOrderList' );

    let data: any = [];
    if( quickOrderList != false ) {
      quickOrderList = JSON.parse(quickOrderList);

      quickOrderList.forEach(item => {
        data.push({
          0: '',
          1: '',
          2: item.parent_category_code,
          3: item.product_seq,
          4: item.count,
          5: '',
          6: '',
          7: '',
          8: ''
        });
      });
    }

    let excelData: any = this.globals.load( 'excelData' );
    if( excelData != false ) {
      // jExcel data insert
      let excel: any = JSON.parse(excelData);
      data = [];
      data = excel.excel;

      this.form.patchValue({
        board_seq: excel.board_seq,
        board_id: excel.board_id,
        project_group: excel.project_group,
        project_name: excel.project_name,
        project_place: excel.project_place,
        notice_sdate: excel.notice_sdate,
        notice_edate: excel.notice_edate,
        contents: excel.contents
      });
    }

    await this.sCategoryService.getCategoryList().then(response => {
      if (response.ResultCode) {
        categoryList = response.data;

        response.data.forEach(item => {
          categoryNameList.push({
            id: item.category_code,
            name: item.category_name
          });
        });
      } else {
        categoryList = [];
      }
    });

    // Get Product List
    await this.sProductService.getProductList( {} ).then(response => {
      if (response.ResultCode) {
        productListAll = response.data;

        response.data.forEach(item => {
          productNameList.push({
            id: item.seq,
            name: item.name,
            image: item.file_path,
            group: item.category_name
          });
        });
      } else {
        productListAll = [];
      }
    });

    const dropdownFilter = function(instance, cell, c, r, source) {
      var value = instance.jexcel.getValueFromCoords(parseInt(c) - 1, r);

      productNameList = [];
      productListAll.forEach(item => {
        if( item.parent_category_code == value.substr(0,3) ) {
          productNameList.push({
            id: item.seq,
            name: item.name,
            image: item.file_path,
            group: item.category_name
          });
        }
      });

      return productNameList;
    };

    table = jexcel(document.getElementById('spreadsheet'), {
      data: data,
      tableWidth: '100%',
      columns: [
        { type: 'text', width: 50, title: '부스'},
        { type: 'text', width: 50, title: '부스명'},
        { type: 'dropdown', width: 70, title: '대분류', source: categoryNameList },
        { type: 'dropdown', width: 100, title: '상품', source: productNameList, autocomplete: true, filter: dropdownFilter },
        { type: 'numeric', width: 50, title: '수량', decimal: ',' },
        { type: 'calendar', width: 70, title: '설치요청일시', options: { format: 'YYYY-MM-DD HH24:00', time: 60 }},
        { type: 'text', width: 50, title: '담당자명'},
        { type: 'text', width: 50, title: '담당자연락처'},
        { type: 'text', width: 100, title: '담당자이메일'},
      ],
      minDimensions: [1, 20],
      /*
      tableOverflow: true,
      tableHeight:'500px',
      */
      freezeColumns: 2,

      onchange: function(instance, cell, c, r, value) {
        if (c == 2) {
          var columnName = jexcel.getColumnNameFromId([parseInt(c) + 1, r]);
          instance.jexcel.setValue(columnName, '');
        }
      },

      onselection: function(instance, x1, y1, x2, y2, origin) {
        var cellName1 = jexcel.getColumnNameFromId([x1, y1]);
        var cellName2 = jexcel.getColumnNameFromId([x2, y2]);

        rowIndex = y1;
        rowDeleteCount = (y2 - y1) + 1;
      }
    });

    if( this.boardSeq != '' ) {
      this.getBoardView();
    }
  }

  /*******************************************************************************
    설  명 : 행 추가
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInsertRow() {
    const data = table.getData();

    table.insertRow();

    if( data.length < 1 ) {
      table.resetSearch();
    }
  }

  /*******************************************************************************
    설  명 : 선택한 행 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDeleteRow() {
    const data = table.getData();

    if( data.length == rowDeleteCount ) {
      this.toastrService.error( '행 전체 또는 마지막 행은 삭제할 수 없습니다.', '');
    } else {
      table.deleteRow(rowIndex, rowDeleteCount);
    }
  }

  /*******************************************************************************
    설  명 : 프로젝트 타입 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectTypeList() {
    this.sCommonService.getCommonListCode('PJT').then(response => {
      if (response.ResultCode) {
        this.projectTypeList = response.data;
      } else {
        this.projectTypeList = [];
      }
    } );
  }

  /*******************************************************************************
    설  명 : 견적문의 상세내역 가져오기
  *******************************************************************************/
  getBoardView() {
    this.sBoardService.getBoardView( this.form.controls.board_id.value, this.form.controls.board_seq.value, true ).then( response => {
      // console.log(response);
      if( response.ResultCode ) {
        this.boardInfo = response.data;

        if( this.boardInfo === null ) {
          this.toastrService.error( '잘못된 접근입니다.', '');
          this.router.navigate(
            ['/mobile/member/mypage/estimate'],
            {
              relativeTo: this.activatedRoute,
              queryParams: {},
              queryParamsHandling: '', // remove to replace all query params by provided
            });
        }

        // 시작날짜
        if( this.boardInfo.notice_sdate != '' && this.boardInfo.notice_sdate != null ) {
          if( typeof this.boardInfo.notice_sdate != 'object' ) {
            this.dateModel = {
              year: parseInt( this.boardInfo.notice_sdate.substr( 0, 4 ) ),
              month: parseInt( this.boardInfo.notice_sdate.substr( 5, 2 ) ),
              day: parseInt( this.boardInfo.notice_sdate.substr( 8, 2 ) )
            };

            this.boardInfo.notice_sdate = this.dateModel;
          }
        } else {
          this.boardInfo.notice_sdate = null;
        }

        // 종료날짜
        if( this.boardInfo.notice_edate != '' && this.boardInfo.notice_edate != null ) {
          if( typeof this.boardInfo.notice_edate != 'object' ) {
            this.dateModel = {
              year: parseInt( this.boardInfo.notice_edate.substr( 0, 4 ) ),
              month: parseInt( this.boardInfo.notice_edate.substr( 5, 2 ) ),
              day: parseInt( this.boardInfo.notice_edate.substr( 8, 2 ) )
            };

            this.boardInfo.notice_edate = this.dateModel;
          }
        } else {
          this.boardInfo.notice_edate = null;
        }

        this.form.patchValue( this.boardInfo );

        table.setData( this.boardInfo.excel );

      } else {
        this.boardInfo = {};
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday(obj: NgbInputDatepicker, check: boolean) {
    if (check) {
      this.form.patchValue({
        notice_sdate: this.calendar.getToday()
      });
      obj.close();
    } else {
      this.form.patchValue({
        notice_edate: this.calendar.getToday()
      });
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 견적 신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if( this.form.valid ) {

      const excelData = table.getJson();

      if( excelData.length < 1 ) {
        this.toastrService.error( '상세내용을 추가 후 입력하시기 바랍니다.', '견적문의');
        return false;
      }

      this.form.patchValue({
        excel: excelData
      });

      // 작성내용 임시 저장
      this.globals.save( 'excelData', this.form.value );

      this.sBoardService.setEstimateSave( this.form ).then(response => {
        if( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '견적문의 성공');

          // 임시 저장된 내역 삭제
          this.globals.deleteData( 'excelData' );

          this.router.navigate(
            ['/mobile/member/mypage/estimate'],
            {
              relativeTo: this.activatedRoute,
              queryParams: {},
              queryParamsHandling: '', // remove to replace all query params by provided
            });
        } else {
          this.toastrService.error(response.ResultMessage, '견적문의 실패');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    }
  }

}
