/*******************************************************************************
 설  명 : 프로젝트 링크로 접속시 상단 헤더 부분
 작성일 : 2019-10-13
 작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { config } from '@app/service/config.service';

import { SProjectService } from '@shop/service/project.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sgheader',
  templateUrl: './gheader.component.html',
  styleUrls: ['./gheader.component.scss']
})
export class SGheaderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public baseURL: string = config.baseUrl;

	public project: any = {};
  public isGetProject: boolean = false;

  public params: any = {
    projectSeq: ''
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
		private activatedRoute: ActivatedRoute,
		private toastrService: ToastrService,
		private sProjectService: SProjectService,
	) { }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    // 파라미터로 프로젝트 번호 넘어오는지 체크
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.projectSeq = ( typeof params.projectSeq == 'undefined' || params.projectSeq == '' ) ? '' : params.projectSeq;

			if( this.params.projectSeq != '' )
				this.getProjectInfo();
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getProjectInfo() {
    this.sProjectService.getProjectInfo( this.params.projectSeq ).then( response => {
      if( response.ResultCode ) {
        this.project = response.data || {};
        this.isGetProject = true;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 링크 복사
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  copyLink() {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(this.project.link).trigger('select');
    document.execCommand("copy");
    $temp.remove();

    this.toastrService.success('복사되었습니다.');
  }

}
