import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

import { SOrderPwfindComponent } from '@shop/page/member/login/order-pwfind/order-pwfind.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  //size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-slogin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class SLoginComponent implements OnInit {

  errorResponse: ApiResponse;
  public memberForm: FormGroup;
  public memberFormErrors = {
    id: '',
    pw: ''
  };

  public nonMemberForm: FormGroup;
  public nonMemberFormErrors = {
    name: '',
    hp1: '',
    hp2: '',
    pw: ''
  };

  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private cookieService: CookieService,
    private sMemberService: SMemberService,
    private modalService: NgbModal,
    private authService: AuthService,
  ) {
    this.buildForm();

    if( this.cookieService.get( 'saveId' ) != '' ) {
      this.memberForm.get('save_id').setValue( true );
      this.memberForm.get('id').setValue( this.cookieService.get( 'saveId' ) );
    }
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.memberForm = this.formBuilder.group({
      id:[ '', [Validators.required] ],
      pw:[ '', [Validators.required] ],
      save_id:[ false ]
    });

    this.nonMemberForm = this.formBuilder.group({
      name:[ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
      pw:[ '', [Validators.required, Validators.maxLength(20)] ],
      hp1:[ '010', [Validators.required] ],
      hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{7,8}$')] ],
    });

    this.nonMemberForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.nonMemberForm, this.nonMemberFormErrors );
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
  }

  member() {
    $(".member-login").css("display", "block");
    $(".nonmember-order").css("display", "none");
  }

  nonmember() {
    $(".member-login").css("display", "none");
    $(".nonmember-order").css("display", "block");
  }

  // 핸드폰번호 selectbox
  selectbox_pulldown(event) {

    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    if (findrowA.parent().hasClass("pulldown")) {
      findrowA.parent().removeClass("pulldown")
    } else {
      findrowA.parent().addClass("pulldown")
    }
  }

  // 핸드폰번호 select
  selectbox_select(event, element) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    findrowA.parent().parent().parent().find(".select-title > span").text(findrowA.text());
    $(".select-box").removeClass("pulldown");

    this.nonMemberForm.get( element ).setValue( event.target.text );
  }

  /*******************************************************************************
    설  명 : 회원 로그인 처리
  *******************************************************************************/
  public memberSubmit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.memberForm, this.memberFormErrors);

    if( this.memberForm.valid ) {
      this.sMemberService.setLogin( this.memberForm )
      .then(response => {
        if( response['ResultCode'] == true ) {
          this.authService.login(response);

          if( this.memberForm.get('save_id').value == true ) {
            this.cookieService.set( 'saveId', this.memberForm.get('id').value );
          } else {
            this.cookieService.delete('saveId');
          }

          this.toastrService.success(response['ResultMessage'], '');
          this.router.navigate(['/main']);
        } else {
          this.toastrService.error(response['ResultMessage'], '');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.memberForm, this.memberFormErrors);
      });
    } else {
      this.memberForm.valueChanges.subscribe(data => {
        this.utilService.updateFormErrors( this.memberForm, this.memberFormErrors );
      });
    }
  }

  /*******************************************************************************
    설  명 : 주문비밀번호찾기
  *******************************************************************************/
  openOrderFindPw() {
    const modalRef = this.modalService.open(SOrderPwfindComponent, options);

    //modalRef.componentInstance.selectedItem = item;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 비회원 로그인 처리
  *******************************************************************************/
  public nonMemberSubmit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.nonMemberForm, this.nonMemberFormErrors);

    if( this.nonMemberForm.valid ) {
      this.sMemberService.setLoginNonMember( this.nonMemberForm )
      .then(response => {
        if( response['ResultCode'] == true ) {
          this.authService.login(response);

          this.toastrService.success(response['ResultMessage'], '');
          this.router.navigate(['/mypage/order/list']);
        } else {
          this.toastrService.error(response['ResultMessage'], '');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.nonMemberForm, this.nonMemberFormErrors);
      });
    }
  }

}
