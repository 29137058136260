import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { RestfulService } from '@app/service/restful.service';
import { AuthService } from '@app/service/auth.service';
import { DiscountService } from '@app/service/discount.service';
import { SOrderService } from '@shop/service/order.service';
import { SProjectService } from '@shop/service/project.service';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SCartService {

	quickOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	quickOrder: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
	isCartChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  mGnbOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mask: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cartBadge: BehaviorSubject<number> = new BehaviorSubject(0);

	isLogIn$: Observable<boolean>;
	isLogIn: boolean;
	
  get getMGnbOpen() {
    return this.mGnbOpen.asObservable();
  }

  get getMask() {
    return this.mask.asObservable();
  }

  get getCartBadge() {
    return this.cartBadge.asObservable();
  }

  constructor(
		private globals: Globals,
		private restful: RestfulService,
		private authService: AuthService,
		private toastrService: ToastrService,
		private discountService: DiscountService,
		private sOrderService: SOrderService,
		private sProjectService: SProjectService,
  ) {
    this.isLogIn$ = this.authService.getIsLogin;

    this.isLogIn$.subscribe( login => {
      this.isLogIn = login;
    });
  }

  /*******************************************************************************
    설  명 : 모바일 gnb 창 열기/닫기
  *******************************************************************************/
	setMGnbOpen( data ) {
		this.mGnbOpen.next( data );
	}

	/*******************************************************************************
		설  명 : 마스크 열기/닫기
	*******************************************************************************/
	setMask( data ) {
		this.mask.next( data );
	}

	/*******************************************************************************
		설  명 : 카트 배지 수량 표시
	*******************************************************************************/
	setCartBadge( data ) {
		this.cartBadge.next( data );
	}

	/*******************************************************************************
		설  명 : 카트 배지 수량 초기화
	*******************************************************************************/
	setInitCartBadge() {
		this.cartBadge.next(0);
	}

  /*******************************************************************************
    설  명 : 장바구니 저장
  *******************************************************************************/
	setAddCart( row: any ) {
		if( this.isLogIn ) {
			// 회원인 경우 장바구니에 저장
			this.sOrderService.setAddCart( row ).then(response => {
				if( response.ResultCode == true ) {
					this.toastrService.success(response.ResultMessage, '장바구니');

					this.isCartChange.next( true );
				} else {
					this.toastrService.error(response.ResultMessage, '장바구니');
				}
			})
			.catch(response => {
				this.toastrService.success(response, '장바구니');
			});

		// 비회원인 경우 쿠키에 저장
		} else {
			let data: any = this.globals.load( 'thefirstpro_cart' );
			let cart: any = ( data !== false ) ? data : [];
			let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

			let check = false;

			// 장바구니에 상품이 있을 경우 수량만 변경
			if( cartList.length > 0 ) {
				cartList.forEach(item => {
					if( item.seq == row.seq ) {
						item.count = parseInt(row.count) + parseInt(item.count);

						check = true;
					}
				});
			}

			// 장바구니에 상품이 없을 경우 추가
			// 저장용량 2M 초과할 수 없음. 최저 정보 저장
			if( ! check ) {
				cartList.push({
					seq: row.seq,
					count: row.count,
					sale_amt: row.sale_amt,
				});
			}

			this.globals.save( 'thefirstpro_cart', cartList );

			this.isCartChange.next( true );
		}
	}

  /*******************************************************************************
    설  명 : 수량 변경
  *******************************************************************************/
	setEditCart( row: any, index: number, newCount: number ) {
		if( newCount < row.min_qty ) {
			this.toastrService.error('해당 상품의 최소수량은 ' + row.min_qty + ' 입니다.', '수량 변경');
		} else {
			// 회원인 경우
			if( this.isLogIn ) {
				row.count = newCount;

				this.sOrderService.setEditCart( row ).then(response => {
					if( response['ResultCode'] == true ) {
						this.toastrService.success(response['ResultMessage'], '수량 변경');
						this.isCartChange.next( true );
					} else {
						this.toastrService.error(response['ResultMessage'], '수량 변경');
					}
				})
				.catch(response => {
				});

			// 비회원인 경우 쿠키에서 수정
			} else {
				let data: any = this.globals.load( 'thefirstpro_cart' );
				let cart: any = ( data !== false ) ? data : [];
				let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

				cartList[index].count = newCount;

				this.globals.save( 'thefirstpro_cart', cartList );

				this.isCartChange.next( true );
			}
		}
	}

  /*******************************************************************************
    설  명 : 장바구니 행 삭제
  *******************************************************************************/
	setDeleteRowCart( row: any, index: any ) {
		let productSeq = row.sp_seq;

		// 회원인 경우 DB에서 삭제
		if( this.isLogIn ) {
			this.sOrderService.setDeleteSelectCart( [row] ).then(response => {
				if( response['ResultCode'] == true ) {
					this.toastrService.success(response['ResultMessage'], '상품삭제');
					this.isCartChange.next( true );
				} else {
					this.toastrService.error(response['ResultMessage'], '상품삭제');
				}
			})
			.catch(response => {
				this.toastrService.error(response, '상품삭제');
			});

		// 비회원인 경우 쿠키에서 삭제
		} else {
			let data: any = this.globals.load( 'thefirstpro_cart' );
			let cart: any = ( data !== false ) ? data : [];
			let cartList: any = ( data !== false ) ? JSON.parse(cart) : [];

			cartList.splice( index, 1 );

			this.globals.save( 'thefirstpro_cart', cartList );

			this.isCartChange.next( true );
		}
	}

  /*******************************************************************************
    설  명 : 장바구니 전체 삭제
  *******************************************************************************/
	setDeleteCart() {
		// 회원인 경우
		if( this.isLogIn ) {
			this.sOrderService.setDeleteCart().then(response => {
				if( response['ResultCode'] == true ) {
					this.toastrService.success(response['ResultMessage'], '장바구니 비우기');
					this.isCartChange.next( true );
				} else {
					this.toastrService.error(response['ResultMessage'], '장바구니 비우기');
				}
			})
			.catch(response => {
				this.toastrService.error(response, '장바구니 비우기');
			});

		// 비회원인 경우
		} else {
			this.globals.deleteData( 'thefirstpro_cart' );
			this.isCartChange.next( true );
		}
	}

}
