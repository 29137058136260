// if( document.location.href.indexOf('localhost') > -1 || document.location.href.indexOf('thefirst_dev') > -1 )
//   var baseURL = 'http://thefirst_dev.o2oz.net';
// else
const baseURL = (window.location.hostname === 'localhost') ? 'http://localhost:8096' : '//' + window.location.hostname;

export const config = {
  production: false,
  baseUrl: baseURL,
  apiBaseUrl: baseURL + "/api",
  apiFileUploadUrl: baseURL + "/api/file/file_upload.php",
  apiImageUploadUrl: baseURL + "/api/file/image_upload.php",
  cookie_token: 'thefirsttoken'
};