import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-pwfind',
  templateUrl: './order-pwfind.component.html',
  styleUrls: ['./order-pwfind.component.scss']
})
export class SOrderPwfindComponent implements OnInit {

	errorResponse: ApiResponse;
	public orderPwFindForm: FormGroup;
  public formErrors = {
		'radio': '',
		'name': '',
		'hp1': '',
		'hp2': ''
	};

  constructor(
		private utilService: UtilService,
		private toastrService: ToastrService,
		private formBuilder: FormBuilder,
		public activeModal: NgbActiveModal,
		public memberService: SMemberService,
		public authService: AuthService,
	) {
		this.buildForm();
	}

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.orderPwFindForm = this.formBuilder.group({
			radio:[ 'hp', [Validators.required] ],
      name:[ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
			hp1:[ '010', [Validators.required] ],
			hp2:[ '', [Validators.required, Validators.pattern('^[0-9]{7,8}$')] ],
    });

    this.orderPwFindForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.orderPwFindForm, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
  }

  // 핸드폰번호 selectbox
  selectbox_pulldown(event) {

    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    if (findrowA.parent().hasClass("pulldown")) {
      findrowA.parent().removeClass("pulldown")
    } else {
			findrowA.parent().addClass("pulldown")
    }
  }

	// SELECT 선택 처리
	selectChange(event, element) {
    var that = $(event.target);
    var findrowA  = that.closest("a");    //  클릭한 행의 A

    findrowA.parent().parent().parent().find(".select-title > span").text(findrowA.text());
    $(".select-box").removeClass("pulldown");

		if( event.target.text == "직접입력" ) {
			this.orderPwFindForm.get( element ).setValue( "" );
		} else
			this.orderPwFindForm.get( element ).setValue( event.target.text );
	}

  /*******************************************************************************
    설  명 : 주문비밀번호찾기 처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.orderPwFindForm, this.formErrors);

    if( this.orderPwFindForm.valid ) {
      this.memberService.setOrderFindPw( this.orderPwFindForm )
			.then( response => {
        if( response.ResultCode ) {
					this.toastrService.success( response.ResultMessage, '' );
					this.activeModal.close();
        } else {
          this.toastrService.error( response.ResultMessage, '' );
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.orderPwFindForm, this.formErrors);
      });
    }
  }

}
