import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { AuthService } from '@app/service/auth.service';

import { SOrderService } from '@shop/service/order.service';
import { SCartService } from '@shop/service/cart.service';
import { SCategoryService } from '@shop/service/category.service';
import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

import { SEstimateComponent } from '@shop/page/member/mypage/estimate-list/estimate/estimate.component';
import { SUseGuideComponent } from '@shop/page/Information/use-guide/use-guide.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-fadeInDown'
};

const options1: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg-1000',
  centered: true,
  windowClass:'modal-fadeInDown'
};

@Component({
  selector: 'app-sgnb',
  templateUrl: './gnb.component.html',
  styleUrls: ['./gnb.component.scss']
})
export class SGnbComponent implements OnInit {

  @Input() isProject: boolean = false;

  isLogin$: Observable<boolean>;

  public memberInfo: any = {};

  public searchText: any = '';

  public categoryList: any = [];
  public category_title1: string = '';
  public params: any = {
    categoryCode: '',
    projectSeq: ''
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sCategoryService: SCategoryService,
    private modalService: NgbModal,
    private memberService: SMemberService,
    private sOrderService: SOrderService,
    private sCartService: SCartService,
    private authService: AuthService,
    private globals: Globals
  ) {
    this.isLogin$ = this.authService.getIsLogin;
  }

  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.categoryCode = ( typeof params.categoryCode == 'undefined' || params.categoryCode == '' ) ? '' : params.categoryCode;
      this.params.projectSeq = ( typeof params.projectSeq == 'undefined' || params.projectSeq == '' ) ? '' : params.projectSeq;
    });

    this.getCategoryList();
  }

  ngAfterViewInit() {
  }

  // 빠른주문 창 슬라이드
  gnb_slide() {
    if($("#gnb-btn").hasClass("slide")) {
      $("#gnb-btn").removeClass("slide");
      $("#gnb").removeClass("slide");
    } else {
      $("#gnb-btn").addClass("slide");
      $("#gnb").addClass("slide");
    }
  }

  /*******************************************************************************
    설  명 : 게시판으로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goBoard( board_id ) {
    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {board_id: board_id, type: ''},
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 메인페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goMain() {
    this.router.navigate(
    ['/main'],
    {
      relativeTo: this.activatedRoute,
      queryParams: {},
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 장바구니 페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goCart() {
    this.router.navigate(
    ['/mypage/cart'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 로그아웃
  *******************************************************************************/
  setLogout() {
    this.memberService.setLogout().then( async response => {
      if( response['ResultCode'] == true ) {
        this.toastrService.success(response['ResultMessage'], '');

        await this.authService.logout('shop');

				await this.sCartService.quickOpen.next( false );

        this.router.navigate(['/main']);
      } else {
        this.toastrService.error(response['ResultMessage'], '');
      }
    })
    .catch(response => {
    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 카테고리 리스트 가져오기
  *******************************************************************************/
  async getCategoryList() {
    await this.sCategoryService.getCategoryList().then( response => {
      if( response.ResultCode ) {
        this.categoryList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 페이지 리스트 새로고침
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadList( row: any ) {
    this.params.categoryCode = row.category_code;
    this.params.searchText = '';
    this.searchText = '';

    this.router.navigate(
    ['/main'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 견적요청
  *******************************************************************************/
  writeEstimate() {
    this.router.navigate(
    ['/mypage/estimate/request'],
    {
      relativeTo: this.activatedRoute,
      queryParams: [],
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });

    /*
    const modalRef = this.modalService.open(SEstimateComponent, options);

    modalRef.componentInstance.boardId = 'estimate';

    modalRef.result.then((result) => {
      this.router.navigate( ['/mypage/estimate'] );
    }, (reason) => {
    });
    */
  }

  /*******************************************************************************
    설  명 : 이용안내
  *******************************************************************************/
  useGuide() {
    const modalRef = this.modalService.open(SUseGuideComponent, options1);

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 상품검색
  *******************************************************************************/
  search() {
    if( this.searchText == '' ) {
      this.toastrService.error( "검색하실 상품명을 입력하신 후 검색하시기 바랍니다." );
      return false;
    }

    this.params = {
      productSeq: '', 
      categoryCode: '', 
      searchText: this.searchText
    };

    this.searchText = '';

    this.router.navigate(
    ['/main'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상품검색 키 입력
  *******************************************************************************/
  searchKey( $event ) {
    if( $event.key == 'Enter' ) {
      this.search();
    }
  }
}
