import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';

declare const daum: any;

@Component({
  selector: 'app-company-introduce',
  templateUrl: './company-introduce.component.html',
  styleUrls: ['./company-introduce.component.scss']
})
export class SCompanyIntroduceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    new daum.roughmap.Lander({
      "timestamp" : "1581018527297",
      "key" : "wweo",
      "mapWidth" : $(".map").width(),
      "mapHeight" : "350"
    }).render();
  }

}
