/*******************************************************************************
  설  명 : 쇼핑몰 - 푸터
  작성일 : 2021-01-24
  작성자 : 송영석
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

import { SCompanyService } from '@shop/service/company.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class SFooterComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  companyInfo: any = {};
  bankInfo: any = {};

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private sCompanyService: SCompanyService
  ) { 
    this.getCompanyInfo();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    
  }

  /*******************************************************************************
    설  명 : 회사 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public getCompanyInfo() {
    this.sCompanyService.getCompanyInfo().then( response => {
      if( response.ResultCode ) {
        this.companyInfo = response.data;
        this.bankInfo = response.bank;
      } else {
        this.companyInfo = {};
        this.bankInfo = {};
      }
    });
  }


}
