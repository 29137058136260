import { NgModule } from '@angular/core';
import {
  Router, Routes, RouterModule,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { DeviceDetectorService } from 'ngx-device-detector';

/* 홈페이지  */
import { SMainComponent } from '@shop/page/main/main.component';

import { SLoginComponent } from '@shop/page/member/login/login.component';
import { SFindIdpwComponent } from '@shop/page/member/find-idpw/find-idpw.component';
import { SResultFindidComponent } from '@shop/page/member/result-findid/result-findid.component';
import { SResultFindpwComponent } from '@shop/page/member/result-findpw/result-findpw.component';
import { SMemberJoinComponent } from '@shop/page/member/member-join/member-join.component';
import { SNonMemberJoinComponent } from '@shop/page/member/non-member-join/non-member-join.component';

import { SGoodsDetailComponent } from '@shop/page/order/goods-detail/goods-detail.component';
import { SOrderComponent } from '@shop/page/order/order/order.component';
import { SOrderResultComponent } from '@shop/page/order/order/order-result/order-result.component';

import { SMypageComponent } from '@shop/page/member/mypage/mypage.component';
import { SPwConfirmComponent } from '@shop/page/member/mypage/pw-confirm/pw-confirm.component';
import { SMemberEditComponent } from '@shop/page/member/mypage/member-edit/member-edit.component';
import { SMypageOrderComponent } from '@shop/page/member/mypage/order/mypage-order.component';
import { SOrderDetailComponent } from '@shop/page/member/mypage/order/order-detail/order-detail.component';
import { SPaymentComponent } from '@shop/page/member/mypage/order/payment/payment.component';
import { SCartComponent } from '@shop/page/member/mypage/cart/cart.component';
import { SEstimateListComponent } from '@shop/page/member/mypage/estimate-list/estimate-list.component';
import { SEstimateRequestComponent } from '@shop/page/member/mypage/estimate-request/estimate-request.component';
import { SCancelListComponent } from '@shop/page/member/mypage/cancel-list/cancel-list.component';
import { SQuestionComponent } from '@shop/page/member/mypage/question/question.component';
import { SQuestionViewComponent } from '@shop/page/member/mypage/question/view/view.component';

import { STermsUseComponent } from '@shop/page/main/footer/policy/terms-use/terms-use.component';
import { SPolicyPrivacyComponent } from '@shop/page/main/footer/policy/policy-privacy/policy-privacy.component';
import { SPolicyEmailComponent } from '@shop/page/main/footer/policy/policy-email/policy-email.component';

import { SBoardComponent } from '@shop/page/board/board/board.component';
import { SBoardViewComponent } from '@shop/page/board/board/view/view.component';

import { SCompanyIntroduceComponent } from '@shop/page/Information/company-introduce/company-introduce.component';

import { SCompleteJoinComponent } from '@shop/page/member/member-join/complete-join/complete-join.component';
import { SPrintEstimateComponent } from '@shop/page/member/mypage/print/estimate/print-estimate.component';
import { SPrintStatementComponent } from '@shop/page/member/mypage/print/statement/print-statement.component';

import { SInistdpayCloseComponent } from '@shop/page/member/mypage/order/payment/inistdpay-close/inistdpay-close.component';
import { SInistdpayPopupComponent } from '@shop/page/member/mypage/order/payment/inistdpay-popup/inistdpay-popup.component';

/* 모바일 */
import { MmainComponent } from '@mobile/page/main/main.component';
import { MloginComponent } from '@mobile/page/member/login/login.component';
import { MfindIdpwComponent } from '@mobile/page/member/login/find-idpw/find-idpw.component';
import { MresultFindidpwComponent } from '@mobile/page/member/login/result-findidpw/result-findidpw.component';
import { MmemberJoinComponent } from '@mobile/page/member/member-join/member-join.component';
import { MgoodsDetailComponent } from '@mobile/page/order/goods-detail/goods-detail.component';

import { MmypageComponent } from '@mobile/page/member/mypage/mypage.component';
import { MpwConfirmComponent } from '@mobile/page/member/mypage/pw-confirm/pw-confirm.component';
import { MmemberEditComponent } from '@mobile/page/member/mypage/member-edit/member-edit.component';
import { MmypageOrderComponent } from '@mobile/page/member/mypage/order/mypage-order.component';
import { MorderDetailComponent } from '@mobile/page/member/mypage/order/order-detail/order-detail.component';
import { MpaymentComponent } from '@mobile/page/member/mypage/order/payment/payment.component';
import { McartComponent } from './mobile/page/member/mypage/cart/cart.component';
import { MestimateListComponent } from '@mobile/page/member/mypage/estimate-list/estimate-list.component';
import { MestimateRequestComponent } from '@mobile/page/member/mypage/estimate-request/estimate-request.component';
import { McancelListComponent } from '@mobile/page/member/mypage/cancel-list/cancel-list.component';
import { MorderCancelAddComponent } from '@mobile/page/member/mypage/cancel-list/add/add.component';
import { MorderCancelEditComponent } from '@mobile/page/member/mypage/cancel-list/edit/edit.component';
import { MquestionComponent } from '@mobile/page/member/mypage/question/question.component';
import { MquestionAnswerComponent } from '@mobile/page/member/mypage/question/answer/answer.component';

import { McompanyIntroduceComponent } from '@mobile/page/Information/company-introduce/company-introduce.component';

import { MboardComponent } from '@mobile/page/board/board.component';
import { MboardViewComponent } from '@mobile/page/board/view/view.component';

/* 공통 서비스 모듈 */
import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { AuthGuard } from '@app/service/auth.guard';
import { AuthLoginCheck } from '@app/service/auth.login.check';
import { AuthService } from '@app/service/auth.service';
import { MemberService } from '@app/service/member.service';

const routes: Routes = [
  /* 홈페이지 path */
  { path: '', redirectTo: 'main', pathMatch: 'full', canActivate: [AuthLoginCheck] },
  { path: 'main', component: SMainComponent, canActivate: [AuthLoginCheck] },


  { path: 'member/mypage/print/estimate', component: SPrintEstimateComponent },
  { path: 'member/mypage/print/statement', component: SPrintStatementComponent },

  { path: 'member', data: [{title: '회원'}],
    children: [
      { path: 'login', component: SLoginComponent, canActivate: [AuthLoginCheck], data: [{title: '로그인'}] },
      { path: 'find', data: [{title: '아이디/비밀번호 찾기'}],
        children: [
          { path: 'idpw', component: SFindIdpwComponent, canActivate: [AuthLoginCheck], data: [{title: ''}] },
          { path: 'id/result', component: SResultFindidComponent, canActivate: [AuthLoginCheck], data: [{title: '아이디 찾기 결과'}] },
          { path: 'pw/result', component: SResultFindpwComponent, canActivate: [AuthLoginCheck], data: [{title: '비밀번호 찾기 결과'}] }
        ]
      },
      { path: 'join', data: [{title: '회원가입'}],
        children: [
          { path: 'form', component: SMemberJoinComponent, canActivate: [AuthLoginCheck], data: [{title: ''}] },
          { path: 'complete', component: SCompleteJoinComponent, data: [{title: '가입완료'}] },
        ]
      },
      { path: 'non', component: SNonMemberJoinComponent, canActivate: [AuthLoginCheck], data: [{title: '비회원주문'}] }
    ]
  },
  { path: 'goods/detail', component: SGoodsDetailComponent, canActivate: [AuthLoginCheck], data: [{title: '상품'}]  },
  { path: 'order', component: SOrderComponent, canActivate: [AuthLoginCheck], data: [{title: '주문하기'}]  },
  { path: 'order/result', component: SOrderResultComponent, canActivate: [AuthLoginCheck], data: [{title: '주문결과'}]  },
  { path: 'mypage', data: [{title: '마이페이지'}],
    children: [
      { path: 'dashboard', component: SMypageComponent, canActivate: [AuthGuard], data: [{title: '대시보드'}] },
      { path: 'pwconfirm', component: SPwConfirmComponent, canActivate: [AuthGuard], data: [{title: '비밀번호 재확인'}] },
      { path: 'edit', component: SMemberEditComponent, canActivate: [AuthGuard], data: [{title: '회원정보수정'}] },
      { path: 'order', data: [{title: '주문내역'}],
        children: [
          { path: 'list', component: SMypageOrderComponent, canActivate: [AuthGuard], data: [{title: ''}] },
          { path: 'detail', component: SOrderDetailComponent, canActivate: [AuthGuard], data: [{title: '주문상세'}] },
          { path: 'payment', component: SPaymentComponent, canActivate: [AuthGuard], data: [{title: '주문결제'}] },
          { path: 'inistdpay', data: [{title: '이니시스 결제'}],
            children: [
              { path: 'close', component: SInistdpayCloseComponent, canActivate: [AuthGuard], data: [{title: '이니시스 창닫기'}] },
              { path: 'open', component: SInistdpayPopupComponent, canActivate: [AuthGuard], data: [{title: '이니시스 팝업'}] }
            ]
          }
        ]
      },
      { path: 'cart', component: SCartComponent, canActivate: [AuthLoginCheck], data: [{title: '장바구니'}] },
      { path: 'estimate', component: SEstimateListComponent, canActivate: [AuthGuard], data: [{title: '견적문의'}] },
      { path: 'estimate/request', component: SEstimateRequestComponent, canActivate: [AuthGuard], data: [{title: '견적문의'}] },
      { path: 'cancel', component: SCancelListComponent, canActivate: [AuthGuard], data: [{title: '취소/환불'}] },
      { path: 'question', component: SQuestionComponent, canActivate: [AuthGuard], data: [{title: '1:1문의'}] },
      { path: 'question/view', component: SQuestionViewComponent, canActivate: [AuthGuard], data: [{title: '상세보기'}] },
    ]
  },
  { path: 'policy', data: [{title: '약관 및 정책'}],
    children: [
      { path: 'termsUse', component: STermsUseComponent, canActivate: [AuthLoginCheck], data: [{title: '이용약관'}] },
      { path: 'privacy', component: SPolicyPrivacyComponent, canActivate: [AuthLoginCheck], data: [{title: '개인정보처리방침'}] },
      { path: 'email', component: SPolicyEmailComponent, canActivate: [AuthLoginCheck], data: [{title: '이메일무단수집거부'}] },
    ]
  },
  { path: 'board',
    data: [
      {board_id: 'install', title:'설치사례'},
      {board_id: 'gallery', title:'전시/행사갤러리'},
      {board_id: 'notice', title:'공지사항'},
      {board_id: 'faq', title:'자주하는질문'},
    ],
    children: [
      { path: 'list', component: SBoardComponent, canActivate: [AuthLoginCheck], data:[{title:''}] },
      { path: 'view', component: SBoardViewComponent, canActivate: [AuthLoginCheck], data:[{title:'내용보기'}] },
    ]
  },
  { path: 'company/introduce', component: SCompanyIntroduceComponent, canActivate: [AuthLoginCheck], data: [{title: '회사소개'}] },

  /* 모바일 path */
  { path: 'mobile', redirectTo: 'mobile/main', pathMatch: 'full', canActivate: [AuthLoginCheck] },
  { path: 'mobile/main', component: MmainComponent, canActivate: [AuthLoginCheck] },

  { path: 'mobile/login', component: MloginComponent, canActivate: [AuthLoginCheck] },
  { path: 'mobile/find/idpw', component: MfindIdpwComponent, canActivate: [AuthLoginCheck] },
  { path: 'mobile/result/findidpw', component: MresultFindidpwComponent, canActivate: [AuthLoginCheck] },
  { path: 'mobile/member/join', component: MmemberJoinComponent, canActivate: [AuthLoginCheck] },
  { path: 'mobile/goods/detail', component: MgoodsDetailComponent, canActivate: [AuthLoginCheck] },

  { path: 'mobile/member/mypage', data: [{title: '마이페이지'}],
    children: [
      { path: 'dashboard', component: MmypageComponent, canActivate: [AuthGuard], data: [{title: '대시보드'}] },
      { path: 'pwconfirm', component: MpwConfirmComponent, canActivate: [AuthGuard], data: [{title: '비밀번호 재확인'}] },
      { path: 'edit', component: MmemberEditComponent, canActivate: [AuthGuard], data: [{title: '회원정보수정'}] },
      { path: 'order', data: [{title: '주문내역'}],
        children: [
          { path: 'list', component: MmypageOrderComponent, canActivate: [AuthGuard], data: [{title: ''}] },
          { path: 'detail', component: MorderDetailComponent, canActivate: [AuthGuard], data: [{title: '주문상세'}] },
          { path: 'payment', component: MpaymentComponent, canActivate: [AuthGuard], data: [{title: '주문결제'}] },
          { path: 'inistdpay', data: [{title: '이니시스 결제'}],
            children: [
              { path: 'close', component: SInistdpayCloseComponent, canActivate: [AuthGuard], data: [{title: '이니시스 창닫기'}] },
              { path: 'open', component: SInistdpayPopupComponent, canActivate: [AuthGuard], data: [{title: '이니시스 팝업'}] }
            ]
          }
        ]
      },
      { path: 'cart', component: McartComponent, canActivate: [AuthLoginCheck], data: [{title: '장바구니'}] },
      { path: 'estimate', component: MestimateListComponent, canActivate: [AuthGuard], data: [{title: '견적문의'}] },
      { path: 'estimate/request', component: MestimateRequestComponent, canActivate: [AuthGuard], data: [{title: '견적문의'}] },
      { path: 'cancel', data: [{title: '취소/환불'}],
        children: [
          { path: 'list', component: McancelListComponent, canActivate: [AuthGuard], data: [{title: ''}] },
          { path: 'add', component: MorderCancelAddComponent, canActivate: [AuthGuard], data: [{title: '취소/환불 추가'}] },
          { path: 'edit', component: MorderCancelEditComponent, canActivate: [AuthGuard], data: [{title: '취소/환불 수정'}] }
        ]
      },
      { path: 'question', component: MquestionComponent, canActivate: [AuthGuard], data: [{title: '1:1문의'}] },
      { path: 'question/answer', component: MquestionAnswerComponent, canActivate: [AuthGuard], data: [{title: '답변하기'}] },
    ]
  },

  { path: 'mobile/board',
    data: [
      { board_id: 'install', title: '설치사례' },
      { board_id: 'gallery', title: '전시/행사갤러리' },
      { board_id: 'notice', title: '공지사항' },
      { board_id: 'faq', title: '자주하는질문' },
    ],
    children: [
      { path: 'list', component: MboardComponent, canActivate: [AuthLoginCheck], data: [{title: ''}] },
      { path: 'view', component: MboardViewComponent, canActivate: [AuthLoginCheck], data: [{title: '내용보기'}] },
    ]
  },
  { path: 'mobile/company/introduce', component: McompanyIntroduceComponent, canActivate: [AuthLoginCheck], data: [{title: '회사소개'}] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {

  deviceInfo = null;

  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {
    // 로딩 처리
    this.router.events.subscribe((event: Event) => {
      switch( true ) {
        case event instanceof NavigationStart: {
          this.deviceInfo = this.deviceService.getDeviceInfo();

          if(
            this.deviceService.isMobile() &&
            location.pathname.indexOf('mobile') < 0 &&
            location.pathname.indexOf('admin') < 0
          ) {
            this.router.initialNavigation();
            window.location.href = '/mobile/main';
            //this.router.navigate(['/mobile/main'], { skipLocationChange: false});
          } else {
            $("#indicator").addClass("indicator");
          }

          break;
        }
        case event instanceof NavigationEnd:
          // 페이지 전환 시 최 상단으로 자동 스크롤
          $(window).scrollTop(0);
          break;
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          $("#indicator").removeClass("indicator");
          break;
        }

        default: {
          break;
        }
      }
    });
  };
}
