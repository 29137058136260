import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SMemberService } from '@shop/service/member.service';

import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pw-confirm',
  templateUrl: './pw-confirm.component.html',
  styleUrls: ['./pw-confirm.component.scss']
})
export class SPwConfirmComponent implements OnInit {

	public memberInfo: any = {};

  public confirmForm: FormGroup;
  public formErrors = {
		id: '',
		pw: ''
  };

  constructor(
		private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
		private router: Router,
		public memberService: SMemberService,
		public authService: AuthService,
	) {
		this.buildForm();
	}

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.confirmForm = this.formBuilder.group({
			id:[ '', [Validators.required] ],
      pw:[ '', [Validators.required] ]
    });

    this.confirmForm.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.confirmForm, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
    });

		this.confirmForm.patchValue({
			id: this.memberInfo.id
		});
  }

  /*******************************************************************************
    설  명 : 비밀번호 확인 처리
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.confirmForm, this.formErrors);

    if( this.confirmForm.valid ) {
      this.memberService.checkPwConfirm( this.confirmForm )
      .then(response => {
        if( response['ResultCode'] == true ) {
					this.memberService.pw_confirm = true;
          this.router.navigate(['/mypage/edit']);
        } else {
          this.toastrService.error(response['ResultMessage'], '비밀번호 재확인 실패');
        }
      })
      .catch(response => {
        this.utilService.handleFormSubmitError(response, this.confirmForm, this.formErrors);
      });
    }
  }

}
