import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';

import { SCommonService } from '@shop/service/common.service';
import { SOrderService } from '@shop/service/order.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cancel-detail',
  templateUrl: './cancel-detail.component.html',
  styleUrls: ['./cancel-detail.component.scss']
})
export class SCancelDetailComponent implements OnInit {
	@Input() refundSeq: any;

	public memberInfo: any = {};
	public refund: any = {};
	public reasonList: any = [];
	public detailList: any = [];

	public approve_total: number = 0;

  errorResponse: ApiResponse;
  public form: FormGroup;
  public formErrors: any = {};

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
		private authService: AuthService,
		private sCommonService: SCommonService,
		private sOrderService: SOrderService,
		public activeModal: NgbActiveModal,
	) {
		this.buildForm();
	}

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      refund_seq: ["", [Validators.required]],
			refund_reason: ["", []],
			refund_memo: ["", []],
			refund_yn: ["", []],
			refund_account: ["", [Validators.required]],
			refund_bank: ["", [Validators.required]],
			refund_depositary: ["", [Validators.required]],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    });
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.authService.getLoginData.subscribe(data => {
      this.memberInfo = data;
			/*
			if( this.memberInfo ) {
				this.form.patchValue({
					refund_bank: this.memberInfo.refund_bank,
					refund_account: this.memberInfo.refund_account,
					refund_depositary: this.memberInfo.refund_depositary
				});
			}
			*/
    });

		this.getCommonListCode();
		this.getRefundDetail();
  }

  /*******************************************************************************
    설  명 : 프로젝트 구분 리스트 가져오기
  *******************************************************************************/
  getCommonListCode() {
    this.sCommonService.getCommonListCode( 'RSN' ).then( response => {
      if( response.ResultCode ) {
        this.reasonList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 환불 상세정보 가져오기
  *******************************************************************************/
  getRefundDetail() {
    this.sOrderService.getRefundDetail( this.refundSeq ).then( response => {
      console.log( response );

      if( response.ResultCode ) {
        this.refund = response.data;
				this.detailList = response.detail;

				this.form.patchValue({
					refund_seq: this.refundSeq,
					refund_reason: this.refund.refund_reason,
					refund_memo: this.refund.refund_memo,
					refund_bank: this.refund.refund_bank,
					refund_account: this.refund.refund_account,
					refund_depositary: this.refund.refund_depositary
				});

				let total: number = 0;
				this.detailList.forEach(item => {
					if( item.refund_status == '0002' ) total = total + parseInt(item.amt);
				});

				this.approve_total = total;

      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 환불계좌정보 변경하기
  *******************************************************************************/
	setRefundAccountSave() {
		this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

		if( this.form.valid ) {
			this.sOrderService.setRefundAccountSave( this.form ).then(response => {
				if( response['ResultCode'] == true ) {
					this.toastrService.success(response['ResultMessage'], '환불계좌정보 변경 성공');
					this.activeModal.close(true);
				} else {
					this.toastrService.error(response['ResultMessage'], '환불계좌정보 변경 실패');
				}
			})
			.catch(response => {
				this.errorResponse = response;
				this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
			});
		}
	}

}
