import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-morder-cancel-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class MorderCancelAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  	$("#mmypage-menu3").addClass("active");   
  }

  // 숫자증가
  qty_plus(event) {
  
    var that = $(event.target);
    var findrowA  = that.closest("i");    //  클릭한 행의 i
    
    $(".orders>.footer>.qty").removeClass("active");
    findrowA.parent().addClass("active");
        
    let tmp: any = $(".active .count").val();
    $(".active>.count").val(parseInt( tmp ) + 1 );
  }

  // 숫자감소
  qty_minus(event) {
  
    var that = $(event.target);
    var findrowA  = that.closest("i");    //  클릭한 행의 i
    
    $(".orders>.footer>.qty").removeClass("active");
    findrowA.parent().addClass("active");
    
    let tmp: any = $(".active .count").val();

    $(".active .count").val(parseInt(tmp) - 1 );
    if ($(".active .count").val() == 0) {
        $(".active .count").val(1);
    }
  }

}
