import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SMemberService } from '@shop/service/member.service';

@Component({
  selector: 'app-result-findid',
  templateUrl: './result-findid.component.html',
  styleUrls: ['./result-findid.component.scss']
})
export class SResultFindidComponent implements OnInit {

  public result: any = '';

  constructor(
    private router: Router,
    public memberService: SMemberService,
  ) {
    if( this.memberService.findResult == '' ) {
      this.router.navigate(['/member/find/idpw']);
    }
  }

  ngOnInit() {
    this.result = this.memberService.findResult;
    this.memberService.findResult = '';
  }

}
