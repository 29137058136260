import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbInputDatepicker, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart-edit',
  templateUrl: './cart-edit.component.html',
  styleUrls: ['./cart-edit.component.scss']
})
export class SCartEditComponent implements OnInit {

  /*******************************************************************************
    설  명 : 전역변수
  *******************************************************************************/
  @Input() cartInfo: any;

  public newQty: any;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.newQty = this.cartInfo.qty;
  }

  /*******************************************************************************
    설  명 : 수량 변경 시
  *******************************************************************************/
  changeCount( event ) {
    this.newQty = event.target.value;

    if( this.newQty < this.cartInfo.min_qty ) {
      this.newQty = this.cartInfo.min_qty;
    }
  }

  /*******************************************************************************
    설  명 : 저장 처리
  *******************************************************************************/
  submit() {
    this.cartInfo.count = this.newQty;
    this.cartInfo.qty = this.newQty;

    this.activeModal.close( this.cartInfo );
  }

  /*******************************************************************************
    설  명 : 창 닫기
  *******************************************************************************/
  setDismiss() {
    this.activeModal.dismiss();
  }

}
