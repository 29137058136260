import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(
    private restful: RestfulService,
    private toastrService: ToastrService,
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  /*******************************************************************************
    설  명 : 상품의 할인 내역을 가져온다.
  *******************************************************************************/
  getDiscountList(project_seq: any, product:any ): Promise<any> {
    return this.restful.post({
        program: 'shop',
        service: 'discount',
        version: '1.0',
        action: 'getDiscountList'
      }, {
        project_seq: project_seq,
        data: product
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
