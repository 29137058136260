import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbInputDatepicker, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatPaginatorIntl } from '@angular/material';

import { UtilService } from '@app/service/util.service';

import { SCommonService } from '@shop/service/common.service';
import { SOrderService } from '@shop/service/order.service';
import { SBoardService } from '@shop/service/board.service';

import * as $ from 'jquery';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { SCancelDetailComponent } from '@shop/page/member/mypage/cancel-list/detail/cancel-detail.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass: 'modal-fadeInDown'
};

@Component({
  selector: 'app-mypage-order',
  templateUrl: './mypage-order.component.html',
  styleUrls: ['./mypage-order.component.scss']
})
export class SMypageOrderComponent implements OnInit {

  public statusList: any = [];
  public orderList: any = [];

  public boardData: any = {};

  public params: any = {
    term: '',
    sdate: '',
    edate: '',
    status: '',
    row: 10,
    no: 0,
    orderSeq: ''
  };

  public pageSizeOptions: number[] = [10, 20, 50, 100];

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private sCommonService: SCommonService,
    private sOrderService: SOrderService,
    private sBoardService: SBoardService,
    private utilService: UtilService,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private domSanitizer: DomSanitizer,
    private matPaginatorIntl: MatPaginatorIntl,
  ) {
    this.matPaginatorIntl.itemsPerPageLabel = '페이지당 라인 수:';
    this.matPaginatorIntl.firstPageLabel = '처음';
    this.matPaginatorIntl.lastPageLabel = '마지막';
    this.matPaginatorIntl.nextPageLabel = '다음';
    this.matPaginatorIntl.previousPageLabel = '이전';
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit() {
    this.getCommonListCode();
    this.getBoardViewIndex();

    this.activatedRoute.queryParams.subscribe(params => {
      this.params.term = ( typeof params.term == 'undefined' || params.term == '' ) ? '' : params.term;
      this.params.sdate = ( typeof params.sdate == 'undefined' || params.sdate == '' ) ? '' : this.utilService.getDate( this.params.sdate );
      this.params.edate = ( typeof params.edate == 'undefined' || params.edate == '' ) ? '' : this.utilService.getDate( this.params.edate );
      this.params.status = ( typeof params.status == 'undefined' || params.status == '') ? '' : params.status;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? 10 : parseInt(params.row);
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? 0 : parseInt(params.no);

      this.getOrderList();
    });
  }

  /*******************************************************************************
    설  명 : 프로젝트 구분 리스트 가져오기
  *******************************************************************************/
  getCommonListCode() {
    this.sCommonService.getCommonListCode( 'ORS' ).then( response => {
      if( response.ResultCode ) {
        this.statusList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '');
      }
    });
  }

  /*******************************************************************************
    설  명 : 주문내역 리스트 가져오기
  *******************************************************************************/
  async getOrderList() {
    await this.sOrderService.getOrderList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.orderList = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '' );
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 게시글 정보 가져오기
    입력값 : 게시판 아이디, 게시판 코드
    리턴값 : 없음
  *******************************************************************************/
  getBoardViewIndex() {
    this.sBoardService.getBoardViewIndex( 'contents', '1000000070000000000' ).then( response => {
      if( response.ResultCode ) {
        this.boardData = response.data;
      } else {
        this.toastrService.error( response.ResultMessage, '주문내역 안내' );
      }
    });
  }

  /*******************************************************************************
    설  명 : 기간 검색 설정
  *******************************************************************************/
  setSearchDate( term ) {
    this.params.term = term;
    this.params.edate = this.utilService.getDate( moment().format('YYYY-MM-DD') );

    if( term == 0 )
      this.params.sdate = this.utilService.getDate( moment().format('YYYY-MM-DD') );
    else if( term == 1 )
      this.params.sdate = this.utilService.getDate( moment().subtract(1, 'weeks').format('YYYY-MM-DD') );
    else if( term == 2 )
      this.params.sdate = this.utilService.getDate( moment().subtract(1, 'months').format('YYYY-MM-DD') );
    else if( term == 3 )
      this.params.sdate = this.utilService.getDate( moment().subtract(3, 'months').format('YYYY-MM-DD') );
    else if( term == 4 )
      this.params.sdate = this.utilService.getDate( moment().subtract(6, 'months').format('YYYY-MM-DD') );
  }

  /*******************************************************************************
    설  명 : 주문취소
  *******************************************************************************/
  setOrderCancel( row ) {
    if( !confirm('선택하신 주문을 취소하시겠습니까?') ) return false;

    this.sOrderService.setOrderCancel( row.seq ).then(response => {
      if( response['ResultCode'] == true ) {
        this.toastrService.success(response['ResultMessage'], '주문 취소');
        this.getOrderList();
      } else {
        this.toastrService.error(response['ResultMessage'], '주문 취소');
      }
    })
    .catch(response => {
      this.toastrService.error(response, '주문 취소');
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( event: any ) {
    this.params.row = event.pageSize;
    this.params.no = event.pageIndex;

    this.router.navigate(
    ['/mypage/order/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 상세보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  view( row: any ) {
    this.params.orderSeq = row.seq;

    this.router.navigate(
    ['/mypage/order/detail'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 거래명세서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openStatement( seq ) {
    let url = '/member/mypage/print/statement?seq=' + seq;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=790, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 견적서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openEstimate( seq ) {
    let url = '/member/mypage/print/estimate?seq=' + seq;

    window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=790, height=800, left=0, top=0' );
  }

  /*******************************************************************************
    설  명 : 이니시스 매출전표 & 현금영수증 출력
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  openReceipt( tid, payMethod ) {
    let url: any = '';

    if( !tid ) {
      this.toastrService.error('결제완료된 내역이 아니거나 거래정보가 없습니다.', '');
      return false;
    }

    if( payMethod == 'CARD' ) {
      url = 'https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=' + tid + '&noMethod=1';
    } else if( payMethod == 'BANK' ) {
      url = 'https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/Cash_mCmReceipt.jsp?noTid=' + tid + '&clpaymethod=22';
    }

    if( url != '' ) {
      window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width=425, height=765, left=0, top=0' );
    }
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday( obj: NgbInputDatepicker, check: any ) {
    if( check == 1 ) {
      this.params.sdate = this.calendar.getToday();
      obj.close();
    } else if( check == 2 ) {
      this.params.edate = this.calendar.getToday();
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 주문가이드 텍스트 처리
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  getSafeHtml( html: any ): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  /*******************************************************************************
    설  명 : 결제하기 링크
    입력값 : 없음
    리턴값 : SafeHtml
  *******************************************************************************/
  setPayment( row: any ) {
    this.router.navigate( ['/mypage/order/payment'], {
      relativeTo: this.activatedRoute,
      queryParams: {orderSeq: row.seq },
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 환불신청 내역 모달창 호출
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  modalRefundInfo( row: any ) {
    const modalRef = this.modalService.open(SCancelDetailComponent, options);

    modalRef.componentInstance.refundSeq = row.refundSeq;

    modalRef.result.then((result) => {
      //console.log( result );
    }, (reason) => {
    });
  }

}
