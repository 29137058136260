import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbInputDatepicker, NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';

import { SCommonService } from '@shop/service/common.service';
import { SOrderService } from '@shop/service/order.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-detail-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss']
})
export class SOrderDetailEditInfoComponent implements OnInit {
  @Input() orderSeq: any;

  public orderInfo: any = {};

  public projectTypeList: any = [];

  errorResponse: ApiResponse;
  public form: FormGroup;
  public formErrors: any = {};

  daumAddressOptions: any = {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  private dateModel: NgbDateStruct;

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private sCommonService: SCommonService,
    private sOrderService: SOrderService,
    private activeModal: NgbActiveModal,
    private calendar: NgbCalendar,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼 생성
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      orderSeq: ['', []],
      project_group: [ '', [Validators.required] ],
      project_name: [ '', [Validators.required] ],
      project_place: [ '', [Validators.required] ],
      start_date: [ null, [Validators.required] ],
      end_date: [ null, [Validators.required] ],
      zipcode: [ '', [] ],
      address: [ '', [] ],
      address_detail: [ '', [] ],
      memo: [ '', [] ],
      detail: [ [], [] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors(this.form, this.formErrors);
    } );
  };

  /*******************************************************************************
    설  명 : 초기화
  *******************************************************************************/
  ngOnInit() {
    this.form.patchValue({
      orderSeq: this.orderSeq
    });

    this.getCommonListCode();
    this.getOrderDetail();
  }

  /*******************************************************************************
    설  명 : 프로젝트 구분 리스트 가져오기
  *******************************************************************************/
  async getCommonListCode() {
    await this.sCommonService.getCommonListCode('PJT').then(response => {
      if (response.ResultCode) {
        this.projectTypeList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    } );
  }

  /*******************************************************************************
    설  명 : 주문 상세정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getOrderDetail() {
    this.sOrderService.getOrderDetail( this.orderSeq ).then(response => {
      //console.log(response);
      if (response.ResultCode) {

        this.orderInfo = response.data;

        // 대여기간 시작날짜
        if( this.orderInfo.start_date != '' && this.orderInfo.start_date != null ) {
          if( typeof this.orderInfo.start_date != 'object' ) {
            this.dateModel = {
              year: parseInt( this.orderInfo.start_date.substr( 0, 4 ) ),
              month: parseInt( this.orderInfo.start_date.substr( 5, 2 ) ),
              day: parseInt( this.orderInfo.start_date.substr( 8, 2 ) )
            };

            this.orderInfo.start_date = this.dateModel;
          }
        } else {
          this.orderInfo.start_date = null;
        }

        // 대여기간 종료날짜
        if( this.orderInfo.end_date != '' && this.orderInfo.end_date != null ) {
          if( typeof this.orderInfo.end_date != 'object' ) {
            this.dateModel = {
              year: parseInt( this.orderInfo.end_date.substr( 0, 4 ) ),
              month: parseInt( this.orderInfo.end_date.substr( 5, 2 ) ),
              day: parseInt( this.orderInfo.end_date.substr( 8, 2 ) )
            };

            this.orderInfo.end_date = this.dateModel;
          }
        } else {
          this.orderInfo.end_date = null;
        }

        this.form.patchValue( this.orderInfo );

        const orderDetailList: any = response.detail;

        if ( orderDetailList.length > 0 ) {
          orderDetailList.forEach(item => {
            // 설치요청일자
            if( item.install_date != '' && item.install_date != null ) {
              if( typeof item.install_date != 'object' ) {
                this.dateModel = {
                  year: parseInt( item.install_date.substr( 0, 4 ) ),
                  month: parseInt( item.install_date.substr( 5, 2 ) ),
                  day: parseInt( item.install_date.substr( 8, 2 ) )
                };

                item.install_date = this.dateModel;
              }
            } else {
              item.install_date = null;
            }
          });
        }

        this.form.patchValue({ detail: orderDetailList });

      } else {
        this.toastrService.error(response.ResultMessage, '');
      }
    } );
  }

  /*******************************************************************************
    설  명 : 저장 처리
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if (this.form.valid) {
      this.sOrderService.setEditOrderInfo( this.form ).then(response => {
        if (response.ResultCode) {
          this.toastrService.success(response.ResultMessage, '');
        } else {
          this.toastrService.error(response.ResultMessage, '');
        }

        this.activeModal.close();
      } )
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      } );
    }
  }

  /*******************************************************************************
    설  명 : 창 닫기
  *******************************************************************************/
  setDismiss() {
    this.activeModal.close();
  }

  /*******************************************************************************
    설  명 : 오늘 선택 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getToday(obj: NgbInputDatepicker, check: boolean) {
    if (check) {
      this.form.patchValue({
        notice_sdate: this.calendar.getToday()
      } );
      obj.close();
    } else {
      this.form.patchValue({
        notice_edate: this.calendar.getToday()
      } );
      obj.close();
    }
  }

  /*******************************************************************************
    설  명 : 다음 주소 처리
  *******************************************************************************/
  setDaumAddressApi(data) {
    // 여기로 주소값이 반환
    this.form.patchValue( {
      zipcode: data.zip,
      address: data.addr
    } );

    $("#address_detail").focus();
  }

}
